import { SET_MESSAGE, CLEAR_MESSAGE } from "../TypeAction";
const initialState = {
  message : "",
  code : 0
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_MESSAGE:
      return { 
        message: payload.message,
        code : payload.code
      };
    case CLEAR_MESSAGE:
      return { 
        message: "",
        code: 0
      };
    default:
      return state;
  }
}