import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { CharacterRemove, configBarChart, configPieChart, NumberSeparator } from "helpers";
import { ViewApexChart } from 'pages-component/';
import ReactSelect from 'react-select';

const ModelViewChart = (props) => {
  const ReducersMaster = props.master;
  const ReducersPayload = props.payload;
  const ReducersParams = props.params;

  const [Kota, setKota] = useState([])
  const [statusFieldInit, setStatusFieldInit] = useState(false) 
  const [variabel, setVariabel] = useState()
  const [variabelPertama, setVariabelPertama] = useState([])
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);

  const refVarPertama = useRef(variabelPertama);
  const refLabels = useRef(labels)
  const refSeries = useRef(series)

  const selectKota = (e) => {
    if (e === null) {
      setKota([{
        label : 'PROVINSI DKI JAKARTA',
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersMaster.jumlahProvinsi.jumlah_penduduk) + 'Jiwa',
        value : '',
      }])

      setVariabelPertama(refVarPertama.current)
      setLabels(refLabels.current)
      setSeries(refSeries.current)
      return false;
    }

    if (Kota.length > 0 && e.label !== Kota[0].label) {
      setKota([{
        label : e.label,
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersMaster.jumlahPerkota[CharacterRemove(e.label, / /g, '_')].jumlah_penduduk) + 'Jiwa',
        value : e.value,
      }])

      setVariabelPertama(refVarPertama.current)
      setLabels(refLabels.current)
      setSeries(refSeries.current)
    }

    setKota([{
      label : e.label,
      description : ' Jumlah Penduduk '+ NumberSeparator(ReducersMaster.jumlahPerkota[CharacterRemove(e.label, / /g, '_')].jumlah_penduduk) + 'Jiwa',
      value : e.value,
    }])
  }

  const reactSelected = (val, action) => {
    if (val === null) {
      return false;
    }

    setVariabelPertama(val)
    let tmpLabel = [];
    let tmpSeries = [];
    val.map((data, i) => {
      tmpLabel.push(data.label)
      tmpSeries.push(variabel[data.value])
    })
    setLabels(tmpLabel)
    setSeries(tmpSeries)
  }

  useEffect(() => {
   if (Kota.length > 0 && Kota[0].label !== 'PROVINSI DKI JAKARTA') {
    setVariabel(ReducersPayload.jumlahPerkota[CharacterRemove(Kota[0].label, / /g, '_')])
   }
  }, [Kota])

  useEffect(() => {
    if (ReducersPayload.jumlahProvinsi.jumlah_penduduk !== 0 ) {
      setVariabel(ReducersPayload.jumlahProvinsi);
      
      setKota([{
        label : 'PROVINSI DKI JAKARTA',
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersMaster.jumlahProvinsi.jumlah_penduduk) + 'Jiwa',
        value : '',
      }])
    }
  }, [ReducersPayload])

  useEffect(() => {
    if (ReducersPayload.fieldReactSelected !== undefined && ReducersPayload.fieldReactSelected.length > 0) {
      if (!statusFieldInit) {
        setStatusFieldInit(true);
      }
    }
  }, [ReducersPayload])

  useEffect(() => {
    if (statusFieldInit) {
      let tmpLabel = [];
      let tmpSeries = [];
      ReducersPayload.fieldReactSelected.map((data, i) => {
        tmpLabel.push(data.label)
        tmpSeries.push(ReducersPayload.jumlahProvinsi[data.value])
      })

      const tmpVariabelPertama = ReducersPayload.fieldReactSelected.map((data, key) => {
        return ReducersPayload.fieldReactSelected[key]
      })


      setVariabelPertama(tmpVariabelPertama);
      setLabels(tmpLabel)
      setSeries(tmpSeries)
    }
  }, [statusFieldInit])

  
  return (
    <Fragment>
        {
           ReducersPayload.isError ? null :
          <div className="hero-wrapper text-dark mt-5">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                    <div className="container">
                      <Row>
                        <Col lg="12" className="px-0">
                          <div className="text-center">
                              <div className="">
                                <h2 className='text-primary'>
                                    {props.title ? props.title : 'Loading Data...'}
                                </h2>
                                <p className="mb-3">
                                    Data Update hasil pendataan 2022
                                </p>
                              </div>
                          </div>
                        </Col>
                        <Col lg="12" md="12">
                            <Row>
                              <Col lg="3" md="3" className="p-0">
                                <ReactSelect 
                                  className="basic-single costume-select mb-2"
                                  placeholder="Pilih Kota"
                                  defaultValue={Kota}
                                  isClearable={true}
                                  isRtl={true}
                                  isSearchable={true}
                                  name="selectKota"
                                  options={ReducersParams.kota}
                                  onChange={selectKota}
                                />
                              </Col>
                              <Col lg="9" md="9" className="p-0 pl-2">
                                <ReactSelect 
                                  isMulti
                                  className="basic-single costume-select mb-2"
                                  placeholder="Pilih Variabel"
                                  defaultValue={variabelPertama}
                                  value={variabelPertama}
                                  isClearable={true}
                                  isRtl={true}
                                  isSearchable={true}
                                  name="selectVarPertama"
                                  options={ReducersPayload.fieldReactSelected}
                                  onChange={(value, action) => reactSelected(value, action)}
                                />
                              </Col>
                            </Row>
                        </Col>
                        <Col xl="6" md="6" className="px-0 pr-1">
                          <Card className="h-100">
                                <CardBody className="pb-0 bg-yellow">
                                  <ViewApexChart config={configPieChart({
                                    title : Kota,
                                    series : series,
                                    labels : labels
                                  })} type={props.type || "donut"} />
                                </CardBody>
                                <CardFooter className="pb-4 font-weight-bold">
                                  Hasil Pendataan 2022 {' - ' +Kota.length > 0 ? Kota[0].description : ''}
                                </CardFooter>
                          </Card>
                        </Col>
                        <Col xl="6" md="6" className="px-0 pl-1">
                          <Card className="h-100">
                                <CardBody className="pb-0 h-100">
                                  <ViewApexChart config={configBarChart({
                                    title : Kota,
                                    series : series,
                                    labels : labels,
                                    isHorizontal : false
                                  })} type="bar" />
                                </CardBody>
                                <CardFooter className="pb-4 font-weight-bold">
                                  Hasil Pendataan 2022 {' - ' +Kota.length > 0 ? Kota[0].description : ''}
                                </CardFooter>
                            </Card>
                        </Col>
                      </Row>
                    </div>
                </div>
              </div>
          </div>
        }
    </Fragment>
  );
};

export default ModelViewChart;