import { BarChart } from 'pages-component';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import ModelViewChart from 'pages-public/TopikDataPublik/ModelViewChart';


const PendidikanDanKeterampilan = () => {
  const { ReducersKependudukan, ReducersWargaAktif, ReducersKota } = useSelector(state => state);

  return (
    <Fragment>
        <div id='section-satu' className="hero-wrapper pt-3 pb-3 bg-gradient-primary">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content pb-2 pt-2">
                <div className="container text-white">
                <Row>
                    <Col lg="6" className="px-0">
                    <div className="text-start">
                        
                        <div className="">
                        <h3 className="font-weight-bold">
                            Dashboard Topik Pengembangan Kehidupan Berkoperasi
                        </h3>
                        <p className="mb-3" style={{
                            fontSize: '12px'
                        }}>
                            Bagian ini menjelaskan data terkait aspek pendidikan yang terdata melalui aplikasi Carik Jakarta. Data yang ditampilkan pada bagian ini terutama jumlah anak usia sekolah. 
                        </p>
                        </div>
                    </div>
                    </Col>
                    <Col lg="6" className="px-0 mx-auto d-flex align-items-center pl-5">
                    
                    </Col>
                </Row>
                </div>
            </div>
            </div>
        </div>

        <ModelViewChart 
            title="Grafik Data Pengembangan Kehidupan Berkoperasi"
            master={ ReducersKependudukan }
            payload= { ReducersWargaAktif }
            params={ ReducersKota } 
        />
        <div className='mb-5' />
    </Fragment>
  );
};

export default PendidikanDanKeterampilan;