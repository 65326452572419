import { SET_MESSAGE, CLEAR_MESSAGE } from "../TypeAction";

export const setMessage = (payload) => (dispatch, getState) => (dispatch({
  type: SET_MESSAGE,
  payload: {
    message : payload.message,
    code : payload.code
  },
}));

export const clearMessage = () => (dispatch, getState) => (dispatch(
  {
    type: CLEAR_MESSAGE,
  }
));