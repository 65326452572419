import { LogoSimPkk, LogoSimPkkWhite } from 'assets/images';
import React, { Fragment, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { Card, CardBody } from 'reactstrap';

const SidebarMaps = () => {
  const { ReducersKota, ReducersKecamatan, ReducersKelurahan } = useSelector(state => state)
  const dispatchKota = useDispatch(state => state.ReducersKota)

  
  const [kota, setKota] = useState([])
  const [kecamatan, setKecamatan] = useState([])
  const [kelurahan, setKelurahan] = useState([])
  const [isDisabled, setIsDisabled] = useState({
    kota : false, 
    kecamatan : true,
    kelurahan : true,
    rw : true,
    rt : true
  })

  const getKota = () => {
    setKota(ReducersKota.kota);
  }

  const getKecamatan = (mapSelectedKotaId) => {
    console.log('Kecamatan ', mapSelectedKotaId);
    const filterKecamatan = ReducersKecamatan.kecamatan.filter((data) => {
        return data.parentId == mapSelectedKotaId;
    })
    setKecamatan(filterKecamatan);
  }

  const getKelurahan = (mapSelectedKecamatanId) => {
    console.log('Kelurahan ', mapSelectedKecamatanId);
    const filterKelurahan = ReducersKelurahan.kelurahan.filter((data) => {
        return data.parentId == mapSelectedKecamatanId;
    })
    setKelurahan(filterKelurahan);
  }

  const selectKota = (e) => {
    if(e === null){
      setIsDisabled({
        ...isDisabled,
        kecamatan : true
      })
      dispatchKota({
        type : 'SET_MAP_KOTA',
        selectedKotaId : null,
        selectedKotaLabel : null, 
      })
    }

    if (e !== null) {
      setIsDisabled({
        ...isDisabled,
        kecamatan : false
      })
      dispatchKota({
        type : 'SET_MAP_KOTA',
        selectedKotaId : e.value,
        selectedKotaLabel : e.label,
      })
    }
  }

  const selectKecamatan = (e) => {
    if(e === null){
      setIsDisabled({
        ...isDisabled,
        kelurahan : true
      })
      dispatchKota({
        type : 'SET_MAP_KECAMATAN',
        selectedKecamatanId : null,
        selectedKecamatanLabel : null, 
      })
    }

    if (e !== null) {
      setIsDisabled({
        ...isDisabled,
        kelurahan : false
      })
      dispatchKota({
        type : 'SET_MAP_KECAMATAN',
        selectedKecamatanId : e.value,
        selectedKecamatanLabel : e.label,
      })
    }
  }

  const selectKelurahan = (e) => {
    if(e === null){
      setIsDisabled({
        ...isDisabled,
        rw : true
      })
      dispatchKota({
        type : 'SET_MAP_KELURAHAN',
        selectedKelurahanId : null,
        selectedKelurahanLabel : null, 
      })
    }

    if (e !== null) {
      setIsDisabled({
        ...isDisabled,
        rw : false
      })
      dispatchKota({
        type : 'SET_MAP_KELURAHAN',
        selectedKelurahanId : e.value,
        selectedKelurahanLabel : e.label,
      })
    }
  }

  useEffect(() => {
    console.log(ReducersKecamatan);
    getKota();
  }, [])

  useEffect(() => {
    if(ReducersKota.mapSelectedKotaId !== null) {
      getKecamatan(ReducersKota.mapSelectedKotaId);
    }
  }, [ReducersKota])

  useEffect(() => {
    if(ReducersKecamatan.mapSelectedKecamatanId !== null) {
      getKelurahan(ReducersKecamatan.mapSelectedKecamatanId);
    }
  }, [ReducersKecamatan])

  return (
    <Fragment>
      <PerfectScrollbar>
        <div className="sidebar-navigation map-sidebar">
          <div className='text-center font-weight-bold pt-5 pb-3'>
            <img src={LogoSimPkkWhite} alt='Logo SIM PKK' width="40%" />
            <h5>Topik Data Sebaran</h5>
          </div>
          <div className='m-3 text-center'>
            Kami sedang menyiapkan peta hasil pendataan untuk anda.
            Silahkan Akses Data Melalui Dashboard SIM PKK.
            {/* <ReactSelect 
              className="basic-single costume-select mb-2"
              placeholder="Pilih Kota"
              defaultValue={kota[0]}
              isClearable={true}
              isRtl={true}
              isSearchable={true}
              name="selectKota"
              options={kota}
              onChange={selectKota}
            />
            <ReactSelect 
              className="basic-single costume-select mb-2"
              placeholder="Pilih Kecamatan"
              defaultValue={kecamatan[0]}
              isClearable={true}
              isDisabled={isDisabled.kecamatan}
              isRtl={true}
              isSearchable={true}
              name="selectKecamatan"
              options={kecamatan}
              onChange={selectKecamatan}
            />
            <ReactSelect 
              className="basic-single costume-select"
              placeholder="Pilih Kelurahan"
              defaultValue={kelurahan[0]}
              isClearable={true}
              isDisabled={isDisabled.kelurahan}
              isRtl={true}
              isSearchable={true}
              name="selectKelurahan"
              options={kelurahan}
              onChange={selectKelurahan}
            /> */}
          </div>
          <Card className="m-3" style={{
              backgroundColor : "#ffffff3b",
            }}>
            <CardBody>
              
            </CardBody>
          </Card>
        </div>
      </PerfectScrollbar>
    </Fragment>
  )
}

export default SidebarMaps;
