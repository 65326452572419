import { faArrowLeft, faEye, faEyeSlash, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SweetAlert, Mixin } from 'pages-component/SweetAlert';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Container, InputGroup } from 'reactstrap';
import { clearMessage } from 'reducers/ReducersMessage';
import { LogoCarik, LogoDppapp, LogoPkk, LogoPusdatinPutih, LogoSimPkk, SampleImage } from "../../assets/images";
import { login, logout } from 'reducers/ReducersAuth';



const LoginDashboard = () => {
    const [showPassword, setShowPassword] = useState(true);
    const history = useHistory();
    const { isLoggedIn, user } = useSelector(state => state.ReducersAuth);
    const { ReducersMessage } = useSelector(state => state);
    const { code } = useSelector(state => state.ReducersMessage);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [formLogin, setFormLogin] = useState({
        username : false,
        password : false
    });


    const actionLogin = (e) => {
        if (!formLogin.username || !formLogin.password) {
            SweetAlert({
                title : 'Oops !',
                text : 'Mohon diisi username, password terlebih dahulu',
                buttonText : 'Baik, saya isi ya',
                icon : 'warning'
            })

            return false;
        }
        setLoading(true);

        dispatch(login(formLogin))
            .then((e) => {
                setLoading(false);
                console.log(user);
            })
            .catch((e) => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (![formLogin.username, formLogin.password].includes(false)) {
            if (isLoggedIn) {
                Mixin.fire({
                    icon : 'success',
                    title : 'Selamat Datang, ' + user.nama
                }).finally(() => {
                        window.open('https://carik.jakarta.go.id/dashboard/#/auth?us='+formLogin.username+'&pw='+formLogin.password, '_self')
                })
            }
        } else {
            dispatch(logout)
        }
    }, [isLoggedIn])

    useEffect(() => {
        if (code != 0) {
            SweetAlert({
                title : 'Oops !',
                text : Error[code],
                buttonText : 'Login kembali',
                icon : 'error'
            }).finally(() => {
                dispatch(clearMessage)
            })
        }
    }, [code])

  return (
    <Fragment>
        <Row className="wrapper-login">
            <Col md="5" className="login-left">
                <div className='align-content-center align-items-center login-card-wrapper'>
                    <div className='login-title-wrapper mb-3'>
                        <div className='text-center'>
                            <img src={LogoSimPkk} width="50%" />
                        </div>
                    </div>
                    <Card className="login-card">
                        <CardBody>
                            <Form>
                                <FormGroup row>
                                    <Label for="exampleEmail" sm={12}>
                                    Username
                                    </Label>
                                    <Col sm={12}>
                                        <Input
                                            type="text"
                                            name="username"
                                            placeholder="Username"
                                            onChange={(e) => setFormLogin({
                                                ...formLogin,
                                                username : e.target.value
                                            })}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="examplePassword" sm={12}>
                                    Password
                                    </Label>
                                    <Col sm={12}>
                                        <InputGroup>
                                            <Input 
                                                type={showPassword ? "password" : "text"}
                                                name="password"
                                                placeholder="Password"
                                                onChange={(e) => setFormLogin({
                                                    ...formLogin,
                                                    password : e.target.value
                                                })}
                                                autoComplete="off"
                                            />
                                            <Button onClick={() => setShowPassword(!showPassword)} color={showPassword ? "danger" : "success"}>
                                                { showPassword ? <> <FontAwesomeIcon icon={faEye} /> Show </> : <><FontAwesomeIcon icon={faEyeSlash} />  Hide</>}
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Button className="p-button" onClick={actionLogin} disabled={loading}>
                                            LOGIN 
                                            { loading ? <span className="spinner-border spinner-border-sm ml-3"></span> : <FontAwesomeIcon icon={faUnlock} className="ml-3" /> }
                                            
                                        </Button>
                                    </Col>
                                    <Col sm={12} className="mt-4">
                                        <Link to="./">
                                            <FontAwesomeIcon icon={faArrowLeft} className="mr-3" />
                                            Kembali kehalaman utama
                                        </Link>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                    <Row style={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        width: "50vh",
                        marginLeft: "10vh"
                        }} className="p-3 pt-4 mt-4">
                            <Col lg="4" md="4" className="text-center">
                                <img src={ LogoDppapp } width="90px"/>
                            </Col>
                            <Col lg="4" md="4" className="text-left">
                                <img src={ LogoPkk } width="115px"/>
                            </Col>
                            <Col lg="4" md="4" className="text-right">
                                <img src={ LogoCarik } width="115px"/>
                            </Col>
                        </Row>
                </div>
            </Col>
            <Col md="7" className="login-right align-content-center align-items-center text-center pt-5 d-none d-lg-flex d-md-flex">
                    <img src={SampleImage} width="80%" />
            </Col>
        </Row>
    </Fragment>
  );
};

export default LoginDashboard;