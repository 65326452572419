import React, { Fragment, Component } from 'react';

import { Link } from 'react-router-dom';
import { Button, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SidebarHeader extends Component {
  render() {
    return (
      <Fragment>
        <div className="app-sidebar--header" style={{
          backgroundColor: "#FFFFFF"
        }}>
        <Nav>
          <NavItem>
              <Nav>
                    <Link to="/dashboard-public"> 
                        Dashboard Pendataan
                    </Link>
              </Nav>
          </NavItem>
      </Nav>
      <Link to="./auth">
          <Button className="login-button">
              Masuk <FontAwesomeIcon icon={['fa', 'unlock']} className="ml-2" />
          </Button>
      </Link>
        </div>
      </Fragment>
    );
  }
}

export default SidebarHeader;
