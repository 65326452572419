import jsonProvinsi from "./json-wilayah/provinsi.json";
import MasterKota from './json-wilayah/kemendagri/MasterKota.json';
import MasterKelurahan from './json-wilayah/kemendagri/MasterKelurahan.json';
import MasterKecamatan from './json-wilayah/kemendagri/MasterKecamatan.json';

class masterSelectServices {
    getProvinsiKemendagri() {
        return jsonProvinsi;
      }
  
    getKotaKemendagri() {
        return MasterKota;
    }

    getKecamatanKemendagri(){
        return MasterKecamatan;
    }

    getKelurahanKemendagri(){
        return MasterKelurahan;
    }
}

export default new masterSelectServices();