import React, { Fragment } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Penduduk, Agama, TidakBekerja, Piramida, Balita, Lansia } from './base'
import { NumberSeparator } from 'helpers';

const Kependudukan = () => {
  const { ReducersKependudukan } = useSelector(state => state);
  

  return (
    <Fragment>
        <div id='section-satu' className="hero-wrapper pt-3 pb-3 bg-gradient-primary">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content pb-2 pt-2">
                <div className="container text-white">
                <Row>
                  <Col lg="6" className="px-0">
                    <div className="text-start">
                        <div className="">
                        <h3 className="font-weight-bold">
                            Dashboard Topik Kependudukan
                        </h3>
                        <p className="mb-3" style={{
                            fontSize: '12px'
                        }}>
                            Data Update hasil pendataan 2022
                        </p>
                        </div>
                    </div>
                  </Col>
                </Row>
                </div>
            </div>
            </div>
        </div>

        <div id='s2-d-publik' className="hero-wrapper mt-3">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content pb-2 pt-2">
                <div className="container">
                <Row>
                    <Col lg="4" className="px-0">
                        <Card className="h-100 active-card">
                            <CardHeader className="pt-4">
                              Jumlah Penduduk DKI Jakarta
                            </CardHeader>
                            <CardBody className="pb-0 pt-0">
                              <h1 className="font-weight-bold mb-0">
                                {NumberSeparator(ReducersKependudukan.jumlahProvinsi.jumlah_penduduk) }
                              </h1>
                            </CardBody>
                            <CardFooter className="pb-4">
                              Hasil Pendataan 2022
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="4" className="px-0 pl-2">
                        <Card className="h-100 active-card">
                            <CardHeader className="pt-4">
                              Jumlah Penduduk DKI Jakarta Laki - Laki
                            </CardHeader>
                            <CardBody className="pb-0 pt-0">
                              <h1 className="font-weight-bold mb-0">
                                { NumberSeparator(ReducersKependudukan.jumlahProvinsi.jumlah_laki_laki) }
                              </h1>
                            </CardBody>
                            <CardFooter className="pb-4">
                              Hasil Pendataan 2022
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="4" className="px-0 pl-2">
                        <Card className="h-100 active-card">
                            <CardHeader className="pt-4">
                              Jumlah Penduduk DKI Jakarta Perempuan
                            </CardHeader>
                            <CardBody className="pb-0 pt-0">
                              <h1 className="font-weight-bold mb-0">
                              { NumberSeparator(ReducersKependudukan.jumlahProvinsi.jumlah_perempuan) }
                              </h1>
                            </CardBody>
                            <CardFooter className="pb-4">
                              Hasil Pendataan 2022
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                </div>
            </div>
            </div>
        </div>
        
        <Piramida />
        <Penduduk />
        <Balita />
        <Lansia />
        <Agama />
        <TidakBekerja />

        <div className='mb-5' />
    </Fragment>
  );
};

export default Kependudukan;