import React, { Fragment, Component } from 'react';
import { Navbar, NavbarToggler, Nav, NavItem, NavLink, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap';

class Topbar extends Component {

  render() {
    return (
      <Fragment>
        <Navbar
            color="white"
            expand="lg"
            light
            style={{
                marginTop : "75px",
                backgroundColor : "white !important",
                height: "50px",
                paddingLeft: "87px",
                paddingRight: "87px"
            }}
        >
            <NavbarToggler onClick={function noRefCheck(){}} />
            <Collapse navbar>
            <Nav
                className="me-auto"
                navbar
                style={{
                    backgroundColor : "white !important",
                    dropShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)"
                }}
            >
                <NavItem>
                    <NavLink href="/components/">
                        Dashboard
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="https://github.com/reactstrap/reactstrap">
                        Registrasi
                    </NavLink>
                </NavItem>
                <UncontrolledDropdown
                    inNavbar
                    nav
                    >
                    <DropdownToggle
                        caret
                        nav
                    >
                        Muskel
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                        Option 1
                        </DropdownItem>
                        <DropdownItem>
                        Option 2
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                        Reset
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
            </Collapse>
        </Navbar>
      </Fragment>
    );
  }
}

// const mapStateToProps = state => ({
//   sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
// });

// const mapDispatchToProps = dispatch => ({
//   setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
// });

export default (Topbar);
