import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeaftletMaps from 'pages-component/LeafetMaps';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, CardBody, CardHeader, Form, FormGroup, CardFooter, Label, Input } from 'reactstrap';

const HighLightKependudukan = () => {
  return (
    <Fragment>
        <Row>
            <Col lg="12" md="12" className="text-left pl-2 mb-3">
                <h4 className='pt-0 mb-0'>
                    Dashboard Kependudukan
                </h4>
                <small className='mb-0'>
                    Lorem Ipsum Generator. Generate lorem ipsum in paragraphs, words or sentences. Optional html markup - paragraph, italic and bold tags.
                </small>
            </Col>
            <Col lg="4" md="4" className="h-100">
                <Row className="h-100">
                    <Col lg="12" md="12" className="px-0 pl-2 mb-3 ">
                        <Card className="active-card">
                            <CardHeader className="pt-4">
                                Jumlah Penduduk DKI Jakarta
                            </CardHeader>
                            <CardBody className="pb-0 pt-0 d-flex">
                                <h2 className="font-weight-bold mb-0">
                                    26.051.997
                                </h2>
                            </CardBody>
                            <CardFooter className="pb-4">
                                Hasil Pendataan 2022
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" className="px-0 pl-2 mb-3 ">
                        <Card >
                            <CardHeader className="pt-4">
                            Jumlah Penduduk DKI Jakarta Laki - Laki
                            </CardHeader>
                            <CardBody className="pb-0 pt-0">
                            <h2 className="font-weight-bold mb-0">
                                26.051.997
                            </h2>
                            </CardBody>
                            <CardFooter className="pb-4">
                            Hasil Pendataan 2022
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" className="px-0 pl-2 mb-3">
                        <Card >
                            <CardHeader className="pt-4">
                            Jumlah Penduduk DKI Jakarta Perempuan
                            </CardHeader>
                            <CardBody className="pb-0 pt-0">
                            <h2 className="font-weight-bold mb-0">
                                26.051.997
                            </h2>
                            </CardBody>
                            <CardFooter className="pb-4">
                            Hasil Pendataan 2022
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" className="px-0 pl-2 h-100">
                        <Card>
                                <CardHeader className="pt-4">
                                Jumlah Penduduk ber KTP DKI Jakarta
                                </CardHeader>
                            <CardBody className="pb-0 pt-0 ">
                                <h2 className="font-weight-bold mb-0">
                                    26.051.997
                                </h2>
                            </CardBody>
                            <CardFooter className="pb-4">
                                Hasil Pendataan 2022
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" className="px-0 pl-2 ">
                        <Card className="h-100">
                            <CardHeader className="pt-4">
                                Jumlah Penduduk KTP Non DKI Jakarta
                            </CardHeader>
                            <CardBody className="pb-0 pt-0 ">
                                <h2 className="font-weight-bold mb-0">
                                    26.051.997
                                </h2>
                            </CardBody>
                            <CardFooter className="pb-4">
                                Hasil Pendataan 2022
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col lg="8" md="8">
                <Card >
                    <CardHeader className="pt-3 pb-3 costume-bg-secondary">
                        <h6 className='mb-0 text-white'>
                            <FontAwesomeIcon icon={['fa', 'map-marker']} className="mr-3" />
                            Sebaran Data DKI Jakarta
                        </h6>
                    </CardHeader>
                    <CardBody className="pb-0 pt-3">
                        <Form>
                            <FormGroup row>
                                <Col>
                                    <Label className="font-weight-bold" for="exampleSelect">Topik Data</Label>
                                    <Input type="select" name="select" id="exampleSelect">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </Input>
                                </Col>
                                <Col className="pl-0">
                                    <Label className="font-weight-bold" for="exampleSelect">Provinsi</Label>
                                    <Input type="select" name="select" id="exampleSelect">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </Input>
                                </Col>
                                <Col className="pl-0">
                                    <Label className="font-weight-bold" for="exampleSelect">Kabupatan/Kota</Label>
                                    <Input type="select" name="select" id="exampleSelect">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </Input>
                                </Col>
                                <Col className="pl-0">
                                    <Label className="font-weight-bold" for="exampleSelect">Kecamatan</Label>
                                    <Input type="select" name="select" id="exampleSelect">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Form>
                        <div style={{
                            minWidth:"100%",
                            minHeight: "100%"
                        }}>
                            <LeaftletMaps />
                        </div>
                    </CardBody>
                    <CardFooter className="pb-2">
                        Hasil Pendataan 2022
                    </CardFooter>
                </Card>
            </Col>
            <Col lg="12 text-right pt-3">
                <Link to="/user/analisa/kependudukan">
                    <Button className="p-button">
                        Dashboard Kependudukan Selengkapnya
                        <FontAwesomeIcon icon={['fa', 'arrow-right']} className="ml-3" />
                    </Button>
                </Link>
            </Col>
        </Row>
        <div className='mb-5' />
    </Fragment>
  );
};

export default HighLightKependudukan;