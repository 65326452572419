import React, { Fragment } from 'react';
import { Footer, Header, HeaderLanding } from '../../layout-components';

import PropTypes from 'prop-types';

const LandingLayout = props => {
  const { children } = props;

  return(
    <Fragment>
        <HeaderLanding />
        {children}
        <Footer />
    </Fragment>
  );
};

LandingLayout.propTypes = {
  children: PropTypes.node
};

export default LandingLayout;
