import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
  } from "../TypeAction";
import initialState from "./InitialState";

  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case REGISTER_SUCCESS:
        return {
          ...state,
          isLoggedIn: false,
        };
      case REGISTER_FAIL:
        return {
          ...state,
          isLoggedIn: false,
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
          wilayah : payload.wilayah,
          levelWilayah : payload.levelWilayah
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          wilayah : null
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
          wilayah : null,
          levelWilayah : null
        };
      default:
        return state;
    }
  }