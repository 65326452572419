import count from "../CountData";

const groupBy = (array, key, field) => {
    let tmp = array.reduce((result, currentValue) => {
      if (!result[currentValue[key]]) {
        result[currentValue[key]] = {
          data : [],
        };
      }
      result[currentValue[key]].data.push(currentValue);
  
      return result;
    }, {}); 
  
    Object.keys(tmp).map((key, index) => {
      field.map((data, i) => {
        tmp[key] = {
          ...tmp[key],
          [data] : count({arr : tmp[key].data, obj : data})
        }
      })
    });
  
    return tmp;
};

export default groupBy;