import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { CharacterRemove, configPieChart, NumberSeparator } from "helpers";
import { ViewApexChart } from 'pages-component/';
import ReactSelect from 'react-select';

const Penduduk = () => {
  const { ReducersKependudukan, ReducersKota } = useSelector(state => state);
  const [kependudukanKota, setKependudukanKota] = useState([])
  const [variabelKependudukan, setVariabelKependudukan] = useState()
  const [variabelPertamaKependudukan, setVariabelPertamaKependudukan] = useState([{
    label : 'Pilih Variabel Pertama',
    value : false,
    series : 50
  }])
  const [variabelKeduaKependudukan, setVariabelKeduaKependudukan] = useState([{
    label : 'Pilih Variabel Kedua',
    value : false,
    series : 50
  }])

  const refKependudukanKota = useRef(kependudukanKota);
  const refVarPertama = useRef(variabelPertamaKependudukan);
  const refVarKedua = useRef(variabelKeduaKependudukan);

  const selectKota = (e) => {
    if (e === null) {
      setVariabelKependudukan(ReducersKependudukan.jumlahProvinsi);
      
      setKependudukanKota([{
        label : 'PROVINSI DKI JAKARTA',
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersKependudukan.jumlahProvinsi.jumlah_penduduk) + 'Jiwa',
        value : '',
      }])

      setVariabelPertamaKependudukan(refVarPertama.current);
      setVariabelKeduaKependudukan(refVarKedua.current);
      
      return false;
    }

    if (kependudukanKota.length > 0 && e.label !== kependudukanKota[0].label) {
      setKependudukanKota([{
        label : e.label,
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersKependudukan.jumlahPerkota[CharacterRemove(e.label, / /g, '_')].jumlah_penduduk) + 'Jiwa',
        value : e.value,
      }])

      setVariabelPertamaKependudukan(refVarPertama.current);
      setVariabelKeduaKependudukan(refVarKedua.current);
      return false;
    }

    setKependudukanKota([{
      label : e.label,
      description : ' Jumlah Penduduk '+ NumberSeparator(ReducersKependudukan.jumlahPerkota[CharacterRemove(e.label, / /g, '_')].jumlah_penduduk) + 'Jiwa',
      value : e.value,
    }])
  }

  const reactSelected = (val, action) => {
    switch (action.name) {
      case 'selectVarPertama':
        if (val === null) {
          setVariabelPertamaKependudukan(refVarPertama.current);
          return false;
        }
        
        setVariabelPertamaKependudukan([{
          ...variabelPertamaKependudukan[0],
          label : val.label,
          series : variabelKependudukan[val.value]
        }])
        break;
      case 'selectVarKedua':
          if (val === null) {
            setVariabelKeduaKependudukan(refVarKedua.current);
            return false;
          }

          setVariabelKeduaKependudukan([{
            ...variabelKeduaKependudukan[0],
            label : val.label,
            series : variabelKependudukan[val.value]
          }])
          break;
    
      default:
        break;
    }
  }

  useEffect(() => {
   if (kependudukanKota.length > 0 && kependudukanKota[0].label !== 'PROVINSI DKI JAKARTA') {
    setVariabelKependudukan(ReducersKependudukan.jumlahPerkota[CharacterRemove(kependudukanKota[0].label, / /g, '_')])
   }

  }, [kependudukanKota])

  useEffect(() => {
    if (ReducersKependudukan.jumlahProvinsi.jumlah_penduduk !== 0) {
      setVariabelKependudukan(ReducersKependudukan.jumlahProvinsi);
      
      setKependudukanKota([{
        label : 'PROVINSI DKI JAKARTA',
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersKependudukan.jumlahProvinsi.jumlah_penduduk) + 'Jiwa',
        value : '',
      }])
    }

  }, [ReducersKependudukan])

  return (
    <Fragment>

        <div id='s2-d-publik' className="hero-wrapper mt-3">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content pb-2">
                  <div className="container">
                    <Row>
                      <Col lg="6" className="px-0">
                        <div className="text-start">
                            <div className="">
                              <h3 className="font-weight-bold">
                                  Grafik Sebaran Penduduk
                              </h3>
                              <p className="mb-3" style={{
                                  fontSize: '12px'
                              }}>
                                  Data Update hasil pendataan 2022
                              </p>
                            </div>
                        </div>
                      </Col>
                      <Col lg="6" md="6">
                      </Col>
                      <Col lg="4" md="4">
                          <Row>
                            <Col lg="12" md="12" className="p-0">
                              <ReactSelect 
                                className="basic-single costume-select mb-2"
                                placeholder="Pilih Kota"
                                defaultValue={kependudukanKota}
                                isClearable={true}
                                isRtl={true}
                                isSearchable={true}
                                name="selectKota"
                                options={ReducersKota.kota}
                                onChange={selectKota}
                              />
                            </Col>
                            <Col lg="12" md="12" className="p-0 pt-2">
                              <ReactSelect 
                                className="basic-single costume-select mb-2"
                                placeholder="Pilih Variabel Pertama"
                                defaultValue={variabelPertamaKependudukan}
                                value={variabelPertamaKependudukan}
                                isClearable={true}
                                isRtl={true}
                                isSearchable={true}
                                name="selectVarPertama"
                                options={ReducersKependudukan.fieldReactSelected}
                                onChange={(value, action) => reactSelected(value, action)}
                              />
                            </Col>
                            <Col lg="12" md="12" className="p-0 pt-2">
                              <ReactSelect 
                                className="basic-single costume-select mb-2"
                                placeholder="Pilih Variabel Kedua"
                                defaultValue={variabelKeduaKependudukan}
                                value={variabelKeduaKependudukan}
                                isClearable={true}
                                isRtl={true}
                                isSearchable={true}
                                name="selectVarKedua"
                                options={ReducersKependudukan.fieldReactSelected}
                                onChange={(value, action) => reactSelected(value, action)}
                              />
                            </Col>
                          </Row>
                      </Col>
                      <Col  xl="8" md="8" >
                        <Card>
                              <CardBody className="pb-0">
                                <ViewApexChart config={configPieChart({
                                  title : kependudukanKota,
                                  series : [variabelPertamaKependudukan[0].series, variabelKeduaKependudukan[0].series],
                                  labels : [variabelPertamaKependudukan[0].label, variabelKeduaKependudukan[0].label]
                                })} type="donut" />
                              </CardBody>
                              <CardFooter className="pb-4 font-weight-bold">
                                Hasil Pendataan 2022 - {kependudukanKota.length > 0 ? kependudukanKota[0].description : ''}
                              </CardFooter>
                          </Card>
                      </Col>
                    </Row>
                  </div>
              </div>
            </div>
        </div>

    </Fragment>
  );
};

export default Penduduk;