import React from "react";

const IndikatorKependudukan = [
    {
        title : "Rasio jumlah dan persentase penduduk laki - laki dan perempuan",
        deskripsi : "",
        grafik : "pie-chart",
        service : ""
    },
    {
        title : "Rasio jumlah dan persentase penduduk KTP DKI dan KTP Non DKI",
        deskripsi : "",
        grafik : "pie-chart",
        service : ""
    },
    {
        title : "Sebaran jumlah agama di DKI Jakarta",
        deskripsi : "",
        grafik : "bar-chart",
        service : ""
    },
    {
        title : "Rasio jumlah dan persentase penduduk berusia lanjut DKI Jakarta",
        deskripsi : "",
        parameter : [
            {
                title : "Total",
                service : ""
            },
            {
                title : "Laki - Laki",
                service : ""
            },
            {
                title : "Perempuan",
                service : ""
            }
        ],
        grafik : "pie-chart",
        service : ""
    },
    {
        title : "Rasio jumlah dan persentase penduduk DKI Jakata memiliki akte",
        deskripsi : "",
        parameter : [
            {
                title : "Total",
                service : ""
            },
            {
                title : "Laki - Laki",
                service : ""
            },
            {
                title : "Perempuan",
                service : ""
            }
        ],
        grafik : "pie-chart",
        service : ""
    },
    {
        title : "Rasio jumlah dan persentase status pernikahan",
        deskripsi : "",
        parameter : [
            {
                title : "Jumlah dan Persentase tingkat pernikahan dan perceraian",
                service : ""
            },
            {
                title : "Jumlah dan Persentase Pernikahan dengan akta nikah dan tanpa akta nikah",
                service : ""
            },
            {
                title : "Jumlah dan Persentase cerai hidup dan cerai mati",
                service : ""
            }
        ],
        grafik : "pie-chart",
        service : ""
    }
]

export default IndikatorKependudukan;