import { endPoint, getParams } from "../../../config/endPoint";
import { client } from "../../../config/Services";
import { setGroupData, ModelBarChart, setFieldData, count } from "helpers";

export const SET_MASTER = 'PKK/DATA_KADER/SET_MASTER';

export const fetchMaster = async (dispatch, getState) => {
    const response = await client.get(endPoint.pkk.jumlahPkk.path, {
        params : {
          page : 2,
          size : 267,
          kota : '',
          kecamatan : '',
          kelurahan : ''
      }
    })

    if (response.status === 200) {
        dispatch({ 
        type: SET_MASTER, 
        payload: response,
        isNull : 0 
        })
    } 

    return fetchField(dispatch, getState)
}

const initialState = {
  master: [],
  field : [],
  fieldReactSelected : [],
  jumlahProvinsi : {},
  jumlahPerkota : null,
  barChart : [],
  pieChart : [],
  isNull : 1,
  isError : 0,
}

export default function reducer(state = initialState, action){
  switch (action.type) {
    case SET_MASTER:
        return {
          ...state,
          master : action.payload.data.data,
        }
    case 'SET_FIELD':
      const calbackVal = setFieldData(state);

      return {
        ...state,
        field : calbackVal.tmpField,
        fieldReactSelected : calbackVal.tmpFieldReactSelect
      }
    case 'SET_JUMLAH_PROV':
        let tmpJumlahProvinsi = null;
        state.field.map((data, i) => {
          tmpJumlahProvinsi = {
            ...tmpJumlahProvinsi,
            [data] : count({arr : state.master, obj : data})
          }
        })

        return {
          ...state,
          jumlahProvinsi : {
            ...state.jumlahProvinsi,
            ...tmpJumlahProvinsi
          }
        };
    case 'SET_JUMLAH_KOTA':
        return {
          ...state,
          jumlahPerkota : setGroupData(state.master, 'kota', state.field)
        };
    case 'SET_JUMLAH_KECAMATAN':
        return {
          ...state
        };
    case 'SET_JUMLAH_KABUPATEN':
        return {
          ...state
        };
    case 'SET_GRAFIK_BATANG':
      return {
        ...state,
        barChart : ModelBarChart(state, {
            payload : state.jumlahPerkota
        })
      };
    case 'SET_GRAFIK_PIE':
      return {
        ...state,
        pieChart : []
      };
    case 'SET_MAP':
      return {
        ...state
      };
    case 'SET_LOADING_PKK':
        return {
          ...state,
          isNull : 0
        };
    default:
      break;
  }

  return state;
}

// thunk function
const fetchField = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_FIELD',
  })
  return fetchJumlahProv(dispatch, getState)
}

const fetchJumlahProv = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_JUMLAH_PROV', 
  })

  return  fetchJumlahKota(dispatch, getState)
}

const fetchJumlahKota = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_JUMLAH_KOTA', 
  })

  return fetchGrafikBatang(dispatch, getState);
}

const fetchGrafikBatang = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_GRAFIK_BATANG',
  })

  return fetchGrafikPie(dispatch, getState)
}

const fetchGrafikPie = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_GRAFIK_PIE',
  })

  return fetchLoadingKader(dispatch, getState)
}

const fetchLoadingKader = async(dispatch, getState) => {
  dispatch({
    type : 'SET_LOADING_PKK'
  })
}