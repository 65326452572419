export const SET_MASTER = 'VARIABEL/SET_MASTER';

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_AGREGAT = "VISUALISASI/AGREGAT";
export const SET_BNBA = "BNBA/CROSTAB";
export const SET_PROVINSI = "DATA/SET_PROVINSI";
export const SET_KOTA = "DATA/SET_KOTA";
export const SET_KECAMATAN = "DATA/SET_KECAMATAN";
export const SET_KELURAHAN = "DATA/SET_KELURAHAN";
export const SET_RW = "DATA/SET_RW";
export const SET_RT = "DATA/SET_RT";

export const SET_TOPIK_DATA = "DASHBOARD/TOPIK_DATA";
export const SET_GRAFIK = "DASHBOARD/GRAFIK";
export const GET_LIST_GRAFIK_CROSTAB = "GRAFIK_CROSTAB/LIST";
export const GET_DATA_GRAFIK_CROSTAB = "GRAFIK_CROSTAB/DATA";
export const START_LOADING = "DATA/START_LOADING";
export const END_LOADING = "DATA/END_LOADING";
export const GET_DATA_PENGAJUAN = "LIST/PENGAJUAN/GET";
export const POST_FROM_PENGAJUAN = "FROM/PENGAJUAN/POST";
export const PUT_FROM_PENGAJUAN = "FROM/PENGAJUAN/PUT";
export const SET_FROM_PENGAJUAN = "FROM/PENGAJUAN/SET";
export const SET_WILAYAH_DASHBOARD = "DASHBOARD/WILAYAH_FILTER";

/*
BUMIL ACTION LIST
*/
export const SET_BUMIL_MASTER ='BUMIL/SET_MASTER';
export const SET_BUMIL_FIELD = "BUMIL/FIELD";
export const SET_BUMIL_PROV = "BUMIL/PROV";
export const SET_BUMIL_KOTA = "BUMIL/KOTA";
export const SET_BUMIL_KECAMATAN = "BUMIL/KECAMATAN";
export const SET_BUMIL_KELURAHAN = "BUMIL/KELURAHAN";
export const SET_BUMIL_GRAFIK_BATANG = "BUMIL/GRAFIK_BATANG";
export const SET_BUMIL_GRAFIK_PIE = "BUMIL/GRAFIK_PIE";
export const SET_BUMIL_MAP = "BUMIL/MAP";

/*
DISABILITAS ACTION LIST
*/
export const SET_DISABILITAS_MASTER ='DISABILITAS/SET_MASTER';
export const SET_DISABILITAS_FIELD = "DISABILITAS/FIELD";
export const SET_DISABILITAS_PROV = "DISABILITAS/PROV";
export const SET_DISABILITAS_KOTA = "DISABILITAS/KOTA";
export const SET_DISABILITAS_KECAMATAN = "DISABILITAS/KECAMATAN";
export const SET_DISABILITAS_KELURAHAN = "DISABILITAS/KELURAHAN";
export const SET_DISABILITAS_GRAFIK_BATANG = "DISABILITAS/GRAFIK_BATANG";
export const SET_DISABILITAS_GRAFIK_PIE = "DISABILITAS/GRAFIK_PIE";
export const SET_DISABILITAS_MAP = "DISABILITAS/MAP";

/*
HIVTB ACTION LIST
*/
export const SET_HIVTB_MASTER ='HIVTB/SET_MASTER';
export const SET_HIVTB_FIELD = "HIVTB/FIELD";
export const SET_HIVTB_PROV = "HIVTB/PROV";
export const SET_HIVTB_KOTA = "HIVTB/KOTA";
export const SET_HIVTB_KECAMATAN = "HIVTB/KECAMATAN";
export const SET_HIVTB_KELURAHAN = "HIVTB/KELURAHAN";
export const SET_HIVTB_GRAFIK_BATANG = "HIVTB/GRAFIK_BATANG";
export const SET_HIVTB_GRAFIK_PIE = "HIVTB/GRAFIK_PIE";
export const SET_HIVTB_MAP = "HIVTB/MAP";

/*
KANKER_PEREMPUAN ACTION LIST
*/
export const SET_KANKER_PEREMPUAN_MASTER ='KANKER_PEREMPUAN/SET_MASTER';
export const SET_KANKER_PEREMPUAN_FIELD = "KANKER_PEREMPUAN/FIELD";
export const SET_KANKER_PEREMPUAN_PROV = "KANKER_PEREMPUAN/PROV";
export const SET_KANKER_PEREMPUAN_KOTA = "KANKER_PEREMPUAN/KOTA";
export const SET_KANKER_PEREMPUAN_KECAMATAN = "KANKER_PEREMPUAN/KECAMATAN";
export const SET_KANKER_PEREMPUAN_KELURAHAN = "KANKER_PEREMPUAN/KELURAHAN";
export const SET_KANKER_PEREMPUAN_GRAFIK_BATANG = "KANKER_PEREMPUAN/GRAFIK_BATANG";
export const SET_KANKER_PEREMPUAN_GRAFIK_PIE = "KANKER_PEREMPUAN/GRAFIK_PIE";
export const SET_KANKER_PEREMPUAN_MAP = "KANKER_PEREMPUAN/MAP";

/*
KESEHATAN_BAYI ACTION LIST
*/
export const SET_KESEHATAN_BAYI_MASTER ='KESEHATAN_BAYI/SET_MASTER';
export const SET_KESEHATAN_BAYI_FIELD = "KESEHATAN_BAYI/FIELD";
export const SET_KESEHATAN_BAYI_PROV = "KESEHATAN_BAYI/PROV";
export const SET_KESEHATAN_BAYI_KOTA = "KESEHATAN_BAYI/KOTA";
export const SET_KESEHATAN_BAYI_KECAMATAN = "KESEHATAN_BAYI/KECAMATAN";
export const SET_KESEHATAN_BAYI_KELURAHAN = "KESEHATAN_BAYI/KELURAHAN";
export const SET_KESEHATAN_BAYI_GRAFIK_BATANG = "KESEHATAN_BAYI/GRAFIK_BATANG";
export const SET_KESEHATAN_BAYI_GRAFIK_PIE = "KESEHATAN_BAYI/GRAFIK_PIE";
export const SET_KESEHATAN_BAYI_MAP = "KESEHATAN_BAYI/MAP";

/*
AGAMA ACTION LIST
*/
export const SET_AGAMA_MASTER ='AGAMA/SET_MASTER';
export const SET_AGAMA_FIELD = "AGAMA/FIELD";
export const SET_AGAMA_PROV = "AGAMA/PROV";
export const SET_AGAMA_KOTA = "AGAMA/KOTA";
export const SET_AGAMA_KECAMATAN = "AGAMA/KECAMATAN";
export const SET_AGAMA_KELURAHAN = "AGAMA/KELURAHAN";
export const SET_AGAMA_GRAFIK_BATANG = "AGAMA/GRAFIK_BATANG";
export const SET_AGAMA_GRAFIK_PIE = "AGAMA/GRAFIK_PIE";
export const SET_AGAMA_MAP = "AGAMA/MAP";

/*
AKTA_WARGA ACTION LIST
*/
export const SET_AKTA_WARGA_MASTER ='AKTA_WARGA/SET_MASTER';
export const SET_AKTA_WARGA_FIELD = "AKTA_WARGA/FIELD";
export const SET_AKTA_WARGA_PROV = "AKTA_WARGA/PROV";
export const SET_AKTA_WARGA_KOTA = "AKTA_WARGA/KOTA";
export const SET_AKTA_WARGA_KECAMATAN = "AKTA_WARGA/KECAMATAN";
export const SET_AKTA_WARGA_KELURAHAN = "AKTA_WARGA/KELURAHAN";
export const SET_AKTA_WARGA_GRAFIK_BATANG = "AKTA_WARGA/GRAFIK_BATANG";
export const SET_AKTA_WARGA_GRAFIK_PIE = "AKTA_WARGA/GRAFIK_PIE";
export const SET_AKTA_WARGA_MAP = "AKTA_WARGA/MAP";

/*
BALITA ACTION LIST
*/
export const SET_BALITA_MASTER ='BALITA/SET_MASTER';
export const SET_BALITA_FIELD = "BALITA/FIELD";
export const SET_BALITA_PROV = "BALITA/PROV";
export const SET_BALITA_KOTA = "BALITA/KOTA";
export const SET_BALITA_KECAMATAN = "BALITA/KECAMATAN";
export const SET_BALITA_KELURAHAN = "BALITA/KELURAHAN";
export const SET_BALITA_GRAFIK_BATANG = "BALITA/GRAFIK_BATANG";
export const SET_BALITA_GRAFIK_PIE = "BALITA/GRAFIK_PIE";
export const SET_BALITA_MAP = "BALITA/MAP";

/*
LANSIA ACTION LIST
*/
export const SET_LANSIA_MASTER ='LANSIA/SET_MASTER';
export const SET_LANSIA_FIELD = "LANSIA/FIELD";
export const SET_LANSIA_PROV = "LANSIA/PROV";
export const SET_LANSIA_KOTA = "LANSIA/KOTA";
export const SET_LANSIA_KECAMATAN = "LANSIA/KECAMATAN";
export const SET_LANSIA_KELURAHAN = "LANSIA/KELURAHAN";
export const SET_LANSIA_GRAFIK_BATANG = "LANSIA/GRAFIK_BATANG";
export const SET_LANSIA_GRAFIK_PIE = "LANSIA/GRAFIK_PIE";
export const SET_LANSIA_MAP = "LANSIA/MAP";

/*
PEKERJAAN ACTION LIST
*/
export const SET_PEKERJAAN_MASTER ='PEKERJAAN/SET_MASTER';
export const SET_PEKERJAAN_FIELD = "PEKERJAAN/FIELD";
export const SET_PEKERJAAN_PROV = "PEKERJAAN/PROV";
export const SET_PEKERJAAN_KOTA = "PEKERJAAN/KOTA";
export const SET_PEKERJAAN_KECAMATAN = "PEKERJAAN/KECAMATAN";
export const SET_PEKERJAAN_KELURAHAN = "PEKERJAAN/KELURAHAN";
export const SET_PEKERJAAN_GRAFIK_BATANG = "PEKERJAAN/GRAFIK_BATANG";
export const SET_PEKERJAAN_GRAFIK_PIE = "PEKERJAAN/GRAFIK_PIE";
export const SET_PEKERJAAN_MAP = "PEKERJAAN/MAP";

/*
PENDUDUK ACTION LIST
*/
export const SET_PENDUDUK_MASTER ='PENDUDUK/SET_MASTER';
export const SET_PENDUDUK_FIELD = "PENDUDUK/FIELD";
export const SET_PENDUDUK_PROV = "PENDUDUK/PROV";
export const SET_PENDUDUK_KOTA = "PENDUDUK/KOTA";
export const SET_PENDUDUK_KECAMATAN = "PENDUDUK/KECAMATAN";
export const SET_PENDUDUK_KELURAHAN = "PENDUDUK/KELURAHAN";
export const SET_PENDUDUK_GRAFIK_BATANG = "PENDUDUK/GRAFIK_BATANG";
export const SET_PENDUDUK_GRAFIK_PIE = "PENDUDUK/GRAFIK_PIE";
export const SET_PENDUDUK_MAP = "PENDUDUK/MAP";

/*
PERNIKAHAN ACTION LIST
*/
export const SET_PERNIKAHAN_MASTER ='PERNIKAHAN/SET_MASTER';
export const SET_PERNIKAHAN_FIELD = "PERNIKAHAN/FIELD";
export const SET_PERNIKAHAN_PROV = "PERNIKAHAN/PROV";
export const SET_PERNIKAHAN_KOTA = "PERNIKAHAN/KOTA";
export const SET_PERNIKAHAN_KECAMATAN = "PERNIKAHAN/KECAMATAN";
export const SET_PERNIKAHAN_KELURAHAN = "PERNIKAHAN/KELURAHAN";
export const SET_PERNIKAHAN_GRAFIK_BATANG = "PERNIKAHAN/GRAFIK_BATANG";
export const SET_PERNIKAHAN_GRAFIK_PIE = "PERNIKAHAN/GRAFIK_PIE";
export const SET_PERNIKAHAN_MAP = "PERNIKAHAN/MAP";

/*
PIRAMIDA_PENDUDUK ACTION LIST
*/
export const SET_PIRAMIDA_PENDUDUK_MASTER ='PIRAMIDA_PENDUDUK/SET_MASTER';
export const SET_PIRAMIDA_PENDUDUK_FIELD = "PIRAMIDA_PENDUDUK/FIELD";
export const SET_PIRAMIDA_PENDUDUK_PROV = "PIRAMIDA_PENDUDUK/PROV";
export const SET_PIRAMIDA_PENDUDUK_KOTA = "PIRAMIDA_PENDUDUK/KOTA";
export const SET_PIRAMIDA_PENDUDUK_KECAMATAN = "PIRAMIDA_PENDUDUK/KECAMATAN";
export const SET_PIRAMIDA_PENDUDUK_KELURAHAN = "PIRAMIDA_PENDUDUK/KELURAHAN";
export const SET_PIRAMIDA_PENDUDUK_GRAFIK_BATANG = "PIRAMIDA_PENDUDUK/GRAFIK_BATANG";
export const SET_PIRAMIDA_PENDUDUK_GRAFIK_PIE = "PIRAMIDA_PENDUDUK/GRAFIK_PIE";
export const SET_PIRAMIDA_PENDUDUK_MAP = "PIRAMIDA_PENDUDUK/MAP";

/*
KESEHATAN_MSY ACTION LIST
*/
export const SET_KESEHATAN_MSY_MASTER ='KESEHATAN_MSY/SET_MASTER';
export const SET_KESEHATAN_MSY_FIELD = "KESEHATAN_MSY/FIELD";
export const SET_KESEHATAN_MSY_PROV = "KESEHATAN_MSY/PROV";
export const SET_KESEHATAN_MSY_KOTA = "KESEHATAN_MSY/KOTA";
export const SET_KESEHATAN_MSY_KECAMATAN = "KESEHATAN_MSY/KECAMATAN";
export const SET_KESEHATAN_MSY_KELURAHAN = "KESEHATAN_MSY/KELURAHAN";
export const SET_KESEHATAN_MSY_GRAFIK_BATANG = "KESEHATAN_MSY/GRAFIK_BATANG";
export const SET_KESEHATAN_MSY_GRAFIK_PIE = "KESEHATAN_MSY/GRAFIK_PIE";
export const SET_KESEHATAN_MSY_MAP = "KESEHATAN_MSY/MAP";

/*
PUSWUS ACTION LIST
*/
export const SET_PUSWUS_MASTER ='PUSWUS/SET_MASTER';
export const SET_PUSWUS_FIELD = "PUSWUS/FIELD";
export const SET_PUSWUS_PROV = "PUSWUS/PROV";
export const SET_PUSWUS_KOTA = "PUSWUS/KOTA";
export const SET_PUSWUS_KECAMATAN = "PUSWUS/KECAMATAN";
export const SET_PUSWUS_KELURAHAN = "PUSWUS/KELURAHAN";
export const SET_PUSWUS_GRAFIK_BATANG = "PUSWUS/GRAFIK_BATANG";
export const SET_PUSWUS_GRAFIK_PIE = "PUSWUS/GRAFIK_PIE";
export const SET_PUSWUS_MAP = "PUSWUS/MAP";

/*
PENGUNAAN_KB ACTION LIST
*/
export const SET_PENGUNAAN_KB_MASTER ='PENGUNAAN_KB/SET_MASTER';
export const SET_PENGUNAAN_KB_FIELD = "PENGUNAAN_KB/FIELD";
export const SET_PENGUNAAN_KB_PROV = "PENGUNAAN_KB/PROV";
export const SET_PENGUNAAN_KB_KOTA = "PENGUNAAN_KB/KOTA";
export const SET_PENGUNAAN_KB_KECAMATAN = "PENGUNAAN_KB/KECAMATAN";
export const SET_PENGUNAAN_KB_KELURAHAN = "PENGUNAAN_KB/KELURAHAN";
export const SET_PENGUNAAN_KB_GRAFIK_BATANG = "PENGUNAAN_KB/GRAFIK_BATANG";
export const SET_PENGUNAAN_KB_GRAFIK_PIE = "PENGUNAAN_KB/GRAFIK_PIE";
export const SET_PENGUNAAN_KB_MAP = "PENGUNAAN_KB/MAP";

/*
LINGKUNGAN_RAWAN ACTION LIST
*/
export const SET_LINGKUNGAN_RAWAN_MASTER ='LINGKUNGAN_RAWAN/SET_MASTER';
export const SET_LINGKUNGAN_RAWAN_FIELD = "LINGKUNGAN_RAWAN/FIELD";
export const SET_LINGKUNGAN_RAWAN_PROV = "LINGKUNGAN_RAWAN/PROV";
export const SET_LINGKUNGAN_RAWAN_KOTA = "LINGKUNGAN_RAWAN/KOTA";
export const SET_LINGKUNGAN_RAWAN_KECAMATAN = "LINGKUNGAN_RAWAN/KECAMATAN";
export const SET_LINGKUNGAN_RAWAN_KELURAHAN = "LINGKUNGAN_RAWAN/KELURAHAN";
export const SET_LINGKUNGAN_RAWAN_GRAFIK_BATANG = "LINGKUNGAN_RAWAN/GRAFIK_BATANG";
export const SET_LINGKUNGAN_RAWAN_GRAFIK_PIE = "LINGKUNGAN_RAWAN/GRAFIK_PIE";
export const SET_LINGKUNGAN_RAWAN_MAP = "LINGKUNGAN_RAWAN/MAP";

/*
PUTUS_SEKOLAH ACTION LIST
*/
export const SET_PUTUS_SEKOLAH_MASTER ='PUTUS_SEKOLAH/SET_MASTER';
export const SET_PUTUS_SEKOLAH_FIELD = "PUTUS_SEKOLAH/FIELD";
export const SET_PUTUS_SEKOLAH_PROV = "PUTUS_SEKOLAH/PROV";
export const SET_PUTUS_SEKOLAH_KOTA = "PUTUS_SEKOLAH/KOTA";
export const SET_PUTUS_SEKOLAH_KECAMATAN = "PUTUS_SEKOLAH/KECAMATAN";
export const SET_PUTUS_SEKOLAH_KELURAHAN = "PUTUS_SEKOLAH/KELURAHAN";
export const SET_PUTUS_SEKOLAH_GRAFIK_BATANG = "PUTUS_SEKOLAH/GRAFIK_BATANG";
export const SET_PUTUS_SEKOLAH_GRAFIK_PIE = "PUTUS_SEKOLAH/GRAFIK_PIE";
export const SET_PUTUS_SEKOLAH_MAP = "PUTUS_SEKOLAH/MAP";

/*
PENGOLAHAN_SAMPAH ACTION LIST
*/
export const SET_PENGOLAHAN_SAMPAH_MASTER ='PENGOLAHAN_SAMPAH/SET_MASTER';
export const SET_PENGOLAHAN_SAMPAH_FIELD = "PENGOLAHAN_SAMPAH/FIELD";
export const SET_PENGOLAHAN_SAMPAH_PROV = "PENGOLAHAN_SAMPAH/PROV";
export const SET_PENGOLAHAN_SAMPAH_KOTA = "PENGOLAHAN_SAMPAH/KOTA";
export const SET_PENGOLAHAN_SAMPAH_KECAMATAN = "PENGOLAHAN_SAMPAH/KECAMATAN";
export const SET_PENGOLAHAN_SAMPAH_KELURAHAN = "PENGOLAHAN_SAMPAH/KELURAHAN";
export const SET_PENGOLAHAN_SAMPAH_GRAFIK_BATANG = "PENGOLAHAN_SAMPAH/GRAFIK_BATANG";
export const SET_PENGOLAHAN_SAMPAH_GRAFIK_PIE = "PENGOLAHAN_SAMPAH/GRAFIK_PIE";
export const SET_PENGOLAHAN_SAMPAH_MAP = "PENGOLAHAN_SAMPAH/MAP";

/*
PTP_LAHAN ACTION LIST
*/
export const SET_PTP_LAHAN_MASTER ='PTP_LAHAN/SET_MASTER';
export const SET_PTP_LAHAN_FIELD = "PTP_LAHAN/FIELD";
export const SET_PTP_LAHAN_PROV = "PTP_LAHAN/PROV";
export const SET_PTP_LAHAN_KOTA = "PTP_LAHAN/KOTA";
export const SET_PTP_LAHAN_KECAMATAN = "PTP_LAHAN/KECAMATAN";
export const SET_PTP_LAHAN_KELURAHAN = "PTP_LAHAN/KELURAHAN";
export const SET_PTP_LAHAN_GRAFIK_BATANG = "PTP_LAHAN/GRAFIK_BATANG";
export const SET_PTP_LAHAN_GRAFIK_PIE = "PTP_LAHAN/GRAFIK_PIE";
export const SET_PTP_LAHAN_MAP = "PTP_LAHAN/MAP";

/*
PTP_PERIKANAN ACTION LIST
*/
export const SET_PTP_PERIKANAN_MASTER ='PTP_PERIKANAN/SET_MASTER';
export const SET_PTP_PERIKANAN_FIELD = "PTP_PERIKANAN/FIELD";
export const SET_PTP_PERIKANAN_PROV = "PTP_PERIKANAN/PROV";
export const SET_PTP_PERIKANAN_KOTA = "PTP_PERIKANAN/KOTA";
export const SET_PTP_PERIKANAN_KECAMATAN = "PTP_PERIKANAN/KECAMATAN";
export const SET_PTP_PERIKANAN_KELURAHAN = "PTP_PERIKANAN/KELURAHAN";
export const SET_PTP_PERIKANAN_GRAFIK_BATANG = "PTP_PERIKANAN/GRAFIK_BATANG";
export const SET_PTP_PERIKANAN_GRAFIK_PIE = "PTP_PERIKANAN/GRAFIK_PIE";
export const SET_PTP_PERIKANAN_MAP = "PTP_PERIKANAN/MAP";

/*
PTP_PETERNAKAN ACTION LIST
*/
export const SET_PTP_PETERNAKAN_MASTER ='PTP_PETERNAKAN/SET_MASTER';
export const SET_PTP_PETERNAKAN_FIELD = "PTP_PETERNAKAN/FIELD";
export const SET_PTP_PETERNAKAN_PROV = "PTP_PETERNAKAN/PROV";
export const SET_PTP_PETERNAKAN_KOTA = "PTP_PETERNAKAN/KOTA";
export const SET_PTP_PETERNAKAN_KECAMATAN = "PTP_PETERNAKAN/KECAMATAN";
export const SET_PTP_PETERNAKAN_KELURAHAN = "PTP_PETERNAKAN/KELURAHAN";
export const SET_PTP_PETERNAKAN_GRAFIK_BATANG = "PTP_PETERNAKAN/GRAFIK_BATANG";
export const SET_PTP_PETERNAKAN_GRAFIK_PIE = "PTP_PETERNAKAN/GRAFIK_PIE";
export const SET_PTP_PETERNAKAN_MAP = "PTP_PETERNAKAN/MAP";

/*
RUMAH_SEHAT ACTION LIST
*/
export const SET_RUMAH_SEHAT_MASTER ='RUMAH_SEHAT/SET_MASTER';
export const SET_RUMAH_SEHAT_FIELD = "RUMAH_SEHAT/FIELD";
export const SET_RUMAH_SEHAT_PROV = "RUMAH_SEHAT/PROV";
export const SET_RUMAH_SEHAT_KOTA = "RUMAH_SEHAT/KOTA";
export const SET_RUMAH_SEHAT_KECAMATAN = "RUMAH_SEHAT/KECAMATAN";
export const SET_RUMAH_SEHAT_KELURAHAN = "RUMAH_SEHAT/KELURAHAN";
export const SET_RUMAH_SEHAT_GRAFIK_BATANG = "RUMAH_SEHAT/GRAFIK_BATANG";
export const SET_RUMAH_SEHAT_GRAFIK_PIE = "RUMAH_SEHAT/GRAFIK_PIE";
export const SET_RUMAH_SEHAT_MAP = "RUMAH_SEHAT/MAP";

/*
UP2K_INDUSTRI ACTION LIST
*/
export const SET_UP2K_INDUSTRI_MASTER ='UP2K_INDUSTRI/SET_MASTER';
export const SET_UP2K_INDUSTRI_FIELD = "UP2K_INDUSTRI/FIELD";
export const SET_UP2K_INDUSTRI_PROV = "UP2K_INDUSTRI/PROV";
export const SET_UP2K_INDUSTRI_KOTA = "UP2K_INDUSTRI/KOTA";
export const SET_UP2K_INDUSTRI_KECAMATAN = "UP2K_INDUSTRI/KECAMATAN";
export const SET_UP2K_INDUSTRI_KELURAHAN = "UP2K_INDUSTRI/KELURAHAN";
export const SET_UP2K_INDUSTRI_GRAFIK_BATANG = "UP2K_INDUSTRI/GRAFIK_BATANG";
export const SET_UP2K_INDUSTRI_GRAFIK_PIE = "UP2K_INDUSTRI/GRAFIK_PIE";
export const SET_UP2K_INDUSTRI_MAP = "UP2K_INDUSTRI/MAP";

/*
SUMBER_AIR ACTION LIST
*/
export const SET_SUMBER_AIR_MASTER ='SUMBER_AIR/SET_MASTER';
export const SET_SUMBER_AIR_FIELD = "SUMBER_AIR/FIELD";
export const SET_SUMBER_AIR_PROV = "SUMBER_AIR/PROV";
export const SET_SUMBER_AIR_KOTA = "SUMBER_AIR/KOTA";
export const SET_SUMBER_AIR_KECAMATAN = "SUMBER_AIR/KECAMATAN";
export const SET_SUMBER_AIR_KELURAHAN = "SUMBER_AIR/KELURAHAN";
export const SET_SUMBER_AIR_GRAFIK_BATANG = "SUMBER_AIR/GRAFIK_BATANG";
export const SET_SUMBER_AIR_GRAFIK_PIE = "SUMBER_AIR/GRAFIK_PIE";
export const SET_SUMBER_AIR_MAP = "SUMBER_AIR/MAP";

/*
DATA_KADER ACTION LIST
*/
export const SET_DATA_KADER_MASTER ='DATA_KADER/SET_MASTER';
export const SET_DATA_KADER_FIELD = "DATA_KADER/FIELD";
export const SET_DATA_KADER_PROV = "DATA_KADER/PROV";
export const SET_DATA_KADER_KOTA = "DATA_KADER/KOTA";
export const SET_DATA_KADER_KECAMATAN = "DATA_KADER/KECAMATAN";
export const SET_DATA_KADER_KELURAHAN = "DATA_KADER/KELURAHAN";
export const SET_DATA_KADER_GRAFIK_BATANG = "DATA_KADER/GRAFIK_BATANG";
export const SET_DATA_KADER_GRAFIK_PIE = "DATA_KADER/GRAFIK_PIE";
export const SET_DATA_KADER_MAP = "DATA_KADER/MAP";

/*
WARGA_AKTIF ACTION LIST
*/
export const SET_WARGA_AKTIF_MASTER ='WARGA_AKTIF/SET_MASTER';
export const SET_WARGA_AKTIF_FIELD = "WARGA_AKTIF/FIELD";
export const SET_WARGA_AKTIF_PROV = "WARGA_AKTIF/PROV";
export const SET_WARGA_AKTIF_KOTA = "WARGA_AKTIF/KOTA";
export const SET_WARGA_AKTIF_KECAMATAN = "WARGA_AKTIF/KECAMATAN";
export const SET_WARGA_AKTIF_KELURAHAN = "WARGA_AKTIF/KELURAHAN";
export const SET_WARGA_AKTIF_GRAFIK_BATANG = "WARGA_AKTIF/GRAFIK_BATANG";
export const SET_WARGA_AKTIF_GRAFIK_PIE = "WARGA_AKTIF/GRAFIK_PIE";
export const SET_WARGA_AKTIF_MAP = "WARGA_AKTIF/MAP";

/*
PROGRAM_PEMERINTAH ACTION LIST
*/
export const SET_PROGRAM_PEMERINTAH_MASTER ='PROGRAM_PEMERINTAH/SET_MASTER';
export const SET_PROGRAM_PEMERINTAH_FIELD = "PROGRAM_PEMERINTAH/FIELD";
export const SET_PROGRAM_PEMERINTAH_PROV = "PROGRAM_PEMERINTAH/PROV";
export const SET_PROGRAM_PEMERINTAH_KOTA = "PROGRAM_PEMERINTAH/KOTA";
export const SET_PROGRAM_PEMERINTAH_KECAMATAN = "PROGRAM_PEMERINTAH/KECAMATAN";
export const SET_PROGRAM_PEMERINTAH_KELURAHAN = "PROGRAM_PEMERINTAH/KELURAHAN";
export const SET_PROGRAM_PEMERINTAH_GRAFIK_BATANG = "PROGRAM_PEMERINTAH/GRAFIK_BATANG";
export const SET_PROGRAM_PEMERINTAH_GRAFIK_PIE = "PROGRAM_PEMERINTAH/GRAFIK_PIE";
export const SET_PROGRAM_PEMERINTAH_MAP = "PROGRAM_PEMERINTAH/MAP";

/**
 * const master
 */
 export const SET_MASTER_BPS ='BPS/SET_MASTER';
 export const SET_LEVEL_FIELD_BPS_MASTER ='LEVEL_FIELD_BPS/SET_MASTER';
 export const SET_LEVEL_PROVINSI_BPS_MASTER ='LEVEL_PROVINSI_BPS/SET_MASTER';


/*
LEVEL KOTA BPS ACTION LIST
*/
export const SET_LEVEL_KOTA_BPS_MASTER ='LEVEL_KOTA_BPS/SET_MASTER';
export const SET_LEVEL_KOTA_BPS_FIELD = "LEVEL_KOTA_BPS/FIELD";
export const SET_LEVEL_KOTA_BPS_PROV = "LEVEL_KOTA_BPS/PROV";
export const SET_LEVEL_KOTA_BPS_MAP = "LEVEL_KOTA_BPS/MAP";

/*
LEVEL KECAMATAN ACTION LIST
*/
export const SET_LEVEL_KECAMATAN_BPS_MASTER ='LEVEL_KECAMATAN_BPS/SET_MASTER';
export const SET_LEVEL_KECAMATAN_BPS_FIELD = "LEVEL_KECAMATAN_BPS/FIELD";
export const SET_LEVEL_KECAMATAN_BPS_MAP = "LEVEL_KECAMATAN_BPS/MAP";


/*
LEVEL KELURAHAN ACTION LIST
*/
export const SET_LEVEL_KELURAHAN_BPS_MASTER ='LEVEL_KELURAHAN_BPS/SET_MASTER';
export const SET_LEVEL_KELURAHAN_BPS_FIELD = "LEVEL_KELURAHAN_BPS/FIELD";
export const SET_LEVEL_KELURAHAN_BPS_MAP = "LEVEL_KELURAHAN_BPS/MAP";

/*
LEVEL RW ACTION LIST
*/
export const SET_LEVEL_RW_BPS_MASTER ='LEVEL_RW_BPS/SET_MASTER';
export const SET_LEVEL_RW_BPS_FIELD = "LEVEL_RW_BPS/FIELD";
export const SET_LEVEL_RW_BPS_MAP = "LEVEL_RW_BPS/MAP";

/*
LEVEL RT ACTION LIST
*/
export const SET_LEVEL_RT_BPS_MASTER ='LEVEL_RT_BPS/SET_MASTER';
export const SET_LEVEL_RT_BPS_FIELD = "LEVEL_RT_BPS/FIELD";
export const SET_LEVEL_RT_BPS_MAP = "LEVEL_RT_BPS/MAP";

export const SET_MASTER_CODE = "MASTER_CODE/BPS";


