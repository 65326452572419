import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import ModelViewChart from 'pages-public/TopikDataPublik/ModelViewChart';
import { ModelViewChartBar, ModelViewChartRumahSehat } from 'pages-component';



const PenghayatanDanPengamalanPancasila = () => {
const { ReducersKota, ReducersKependudukan, ReducersPengolahanSampah, ReducersRumahPTPLahan, ReducersRumahPtpPerikanan, ReducersRumahPtpPeternakan, ReducersRumahSehat, ReducersUp2kIndustri, ReducersSumberAir  } = useSelector(state => state);

useEffect(() => {
    console.log('ReducersRumahSehat', ReducersRumahSehat);
}, [ReducersRumahSehat])
return (
    <Fragment>
        <div id='section-satu' className="hero-wrapper pt-3 pb-3 bg-gradient-primary">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content pb-2 pt-2">
                <div className="container text-white">
                <Row>
                    <Col lg="6" className="px-0">
                    <div className="text-start">
                        
                        <div className="">
                        <h3 className="font-weight-bold">
                            Dashboard Topik Penghayatan Dan Pengamalan Pancasila
                        </h3>
                        <p className="mb-3" style={{
                            fontSize: '12px'
                        }}>
                            Data Update hasil pendataan 2022
                        </p>
                        </div>
                    </div>
                    </Col>
                    <Col lg="6" className="px-0 mx-auto d-flex align-items-center pl-5">
                    
                    </Col>
                </Row>
                </div>
            </div>
            </div>
        </div>
        <ModelViewChartBar 
            title="Grafik Data Rumah PTP Lahan"
            master={ ReducersKependudukan }
            payload= { ReducersRumahPTPLahan }
            params={ ReducersKota } 
        />
        <ModelViewChartBar 
            title="Grafik Data Rumah PTP Perikanan"
            master={ ReducersKependudukan }
            payload= { ReducersRumahPtpPerikanan }
            params={ ReducersKota } 
        />
        <ModelViewChartBar 
            title="Grafik Data Rumah PTP Peternakan"
            master={ ReducersKependudukan }
            payload= { ReducersRumahPtpPeternakan }
            params={ ReducersKota } 
        />
        <ModelViewChartBar 
            title="Grafik Data Rumah Sehat"
            master={ ReducersKependudukan }
            payload= { ReducersRumahSehat }
            params={ ReducersKota } 
        />
        <ModelViewChartBar 
            title="Grafik Data Rumah UP2K Industri"
            master={ ReducersKependudukan }
            payload= { ReducersUp2kIndustri }
            params={ ReducersKota } 
        />
        <ModelViewChartBar 
            title="Grafik Data Sumber Air"
            master={ ReducersKependudukan }
            payload= { ReducersSumberAir }
            params={ ReducersKota } 
        />
        <div className='mb-5' />
    </Fragment>
  );
};

export default PenghayatanDanPengamalanPancasila;