import React, { Fragment } from 'react';
import { Footer, HeaderDashboardPublic } from '../../layout-components';
import PropTypes from 'prop-types';

const DashboardPublicLayout = props => {
const { children } = props;

  return(
    <Fragment>
        <HeaderDashboardPublic />
        {children}
        <Footer />
    </Fragment>
  );
};

DashboardPublicLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardPublicLayout;
