import Swal from 'sweetalert2'

export const SweetAlert = ({title, text, icon, buttonText}) => {
    return Swal.fire({
        title: title,
        text:  text,
        icon: icon,
        confirmButtonText: buttonText,
        customClass: "custom_button",
    })
}

export const Mixin = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

