import { SET_MASTER } from "reducers/Action";
import { endPoint, getParams } from "../../../config/endPoint";
import { client } from "../../../config/Services";
import { CharacterRemove, ModelBarChart } from "../../../helpers";

const initialState = {
  master: [],
  field : [],
  fieldReactSelected : [],
  jumlahProvinsi : {
      jumlah_penduduk : 0,
      jumlah_laki_laki : 0,
      jumlah_perempuan : 0,
      jumlah_ktp_non_dki : 0,
      jumlah_ktp_dki : 0,
      jml_anak : 0,
      jumlah_remaja : 0
  },
  jumlahPerkota : null,
  barChart : [],
  pieChart : [],
  isNull : 1,
  isError : 0,
}

const count = (prop) => {
  let total = 0
  prop.arr.reduce((key, currentValue) => {
    total += currentValue[prop.obj];
  }, 0);

  return total;
}

const groupBy = (array, key, field) => {
  let tmp = array.reduce((result, currentValue) => {
    if (!result[currentValue[key]]) {
      result[currentValue[key]] = {
        data : [],
      };
    }
    result[currentValue[key]].data.push(currentValue);

    return result;
  }, {}); 
  
  Object.keys(tmp).map((key, index) => {
      tmp[key] = {
        ...tmp[key],
        jumlah_penduduk : count({arr : tmp[key].data, obj : 'jumlah_penduduk'}),
        jumlah_laki_laki : count({arr : tmp[key].data, obj : 'jumlah_laki_laki'}),
        jumlah_perempuan : count({arr : tmp[key].data, obj : 'jumlah_perempuan'}),
        jumlah_ktp_non_dki : count({arr : tmp[key].data, obj : 'jumlah_ktp_non_dki'}),
        jumlah_ktp_dki : count({arr : tmp[key].data, obj : 'jumlah_ktp_dki'}),
        jml_anak : count({arr : tmp[key].data, obj : 'jml_anak'}),
        jumlah_remaja : count({arr : tmp[key].data, obj : 'jumlah_remaja'}),
      }
  });

  return tmp;
};

export default function reducer(state = initialState, action){
  switch (action.type) {
    case 'SET_DATA_KEPENDUDUKAN':
        return {
          ...state,
          master : action.payload.data.data,
          isNull : action.isNull
        }
    case 'SET_FIELD':
      let tmpField = [];
      let tmpFieldReactSelect = [];
      Object.keys(state.master.length > 0 ? state.master[0] : []).map((key0, index) => {
        if (["id", "kota", "kecamatan", "kelurahan", "rw", "rt", "createdAt", "updatedAt", "kode_wilayah"].includes(key0) ) {
            return false;
        }

        tmpField.push(key0);
        if (key0 === 'jumlah_penduduk') {
          return false;
        }
        tmpFieldReactSelect.push({
          label : CharacterRemove(key0, /_/g, ' '),
          value : key0
        });
      })
      return {
        ...state,
        field : tmpField,
        fieldReactSelected : tmpFieldReactSelect
      }
    case 'SET_JUMLAH_PROV':
        return {
          ...state,
          jumlahProvinsi : {
            ...state.jumlahProvinsi,
            jumlah_penduduk : count({arr : state.master, obj : 'jumlah_penduduk'}),
            jumlah_laki_laki : count({arr : state.master, obj : 'jumlah_laki_laki'}),
            jumlah_perempuan : count({arr : state.master, obj : 'jumlah_perempuan'}),
            jumlah_ktp_non_dki : count({arr : state.master, obj : 'jumlah_ktp_non_dki'}),
            jumlah_ktp_dki : count({arr : state.master, obj : 'jumlah_ktp_dki'}),
            jml_anak : count({arr : state.master, obj : 'jml_anak'}),
            jumlah_remaja : count({arr : state.master, obj : 'jumlah_remaja'}),
          }
        };
    case 'SET_JUMLAH_KOTA':
        return {
          ...state,
          jumlahPerkota : groupBy(state.master, 'kota', state.field)
        };
    case 'SET_JUMLAH_KECAMATAN':
        return {
          ...state
        };
    case 'SET_JUMLAH_KABUPATEN':
        return {
          ...state
        };
    case 'SET_GRAFIK_BATANG':
      return {
        ...state,
        barChart : ModelBarChart(state, {
          payload : state.jumlahPerkota
        })
      };
    case 'SET_GRAFIK_PIE':
      return {
        ...state,
        pieChart : []
      };
    case 'SET_MAP':
      return {
        ...state
      };
    default:
      break;
  }

  return state;
}

// thunk function
export const fetchKependudukan = async (dispatch, getState) => {
  const response = await client.get(endPoint.kependudukan.path, {
    params : getParams
  })

  if (response.status === 200) {
    dispatch({ 
      type: 'SET_DATA_KEPENDUDUKAN', 
      payload: response,
      isNull : 0 
    })
  } 

  return fetchField(dispatch, getState)
}


const fetchField = async (dispatch, getState) => {
    dispatch({ 
      type: 'SET_FIELD',
    })
  return fetchJumlahProv(dispatch, getState)
}


const fetchJumlahProv = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_JUMLAH_PROV', 
  })

  return  fetchJumlahKota(dispatch, getState)
}

const fetchJumlahKota = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_JUMLAH_KOTA', 
  })

  return fetchGrafikBarang(dispatch, getState);
}

const fetchGrafikBarang = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_GRAFIK_BATANG',
  })

  return fetchGrafikPie(dispatch, getState)
}

const fetchGrafikPie = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_GRAFIK_PIE',
  })
  
  return fetchVariabel(dispatch, getState)
}

const fetchVariabel = async (dispatch, getState) => {
  dispatch({
    type : SET_MASTER,
    payload : {
      kependudukan : getState().ReducersKependudukan.fieldReactSelected
    }
  })
}