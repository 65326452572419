// kota
import masterServiceWilayah from '../../config/Wilayah';

export default function reducer(
  state = {
    kelurahan: masterServiceWilayah.getKelurahanKemendagri(),
    mapSelectedKelurahanId : null,
    mapSelectedKelurahanLabel : null,
  },
  action
) {
  switch (action.type) {
    case 'SET_MAP_KELURAHAN':
      return {
        ...state,
        mapSelectedKelurahanId: action.selectedKelurahanId,
        mapSelectedKelurahanLabel: action.selectedKelurahanLabel
      };
    default:
      break;
  }
  return state;
}
