import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { CharacterRemove, configBarChart, configPieChart, NumberSeparator } from "helpers";
import { ViewApexChart } from 'pages-component/';
import ReactSelect from 'react-select';

const Balita = () => {
  const { ReducersKependudukan, ReducersBalita, ReducersKota } = useSelector(state => state);
  const [Kota, setKota] = useState([])
  const [variabel, setVariabel] = useState()
  const [variabelPertama, setVariabelPertama] = useState([])
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);

  const refKota = useRef(Kota);
  const refVarPertama = useRef(variabelPertama);
  const refLabels = useRef(labels)
  const refSeries = useRef(series)

  const selectKota = (e) => {
    if (e === null) {
      setKota([{
        label : 'PROVINSI DKI JAKARTA',
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersKependudukan.jumlahProvinsi.jumlah_penduduk) + 'Jiwa',
        value : '',
      }])

      setVariabelPertama(refVarPertama.current)
      setLabels(refLabels.current)
      setSeries(refSeries.current)
      return false;
    }

    if (Kota.length > 0 && e.label !== Kota[0].label) {
      setKota([{
        label : e.label,
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersKependudukan.jumlahPerkota[CharacterRemove(e.label, / /g, '_')].jumlah_penduduk) + 'Jiwa',
        value : e.value,
      }])

      setVariabelPertama(refVarPertama.current)
      setLabels(refLabels.current)
      setSeries(refSeries.current)
    }

    setKota([{
      label : e.label,
      description : ' Jumlah Penduduk '+ NumberSeparator(ReducersKependudukan.jumlahPerkota[CharacterRemove(e.label, / /g, '_')].jumlah_penduduk) + 'Jiwa',
      value : e.value,
    }])
  }

  const reactSelected = (val, action) => {
    if (val === null) {
      return false;
    }

    setVariabelPertama(val)
    let tmpLabel = [];
    let tmpSeries = [];
    val.map((data, i) => {
      tmpLabel.push(data.label)
      tmpSeries.push(variabel[data.value])
    })
    setLabels(tmpLabel)
    setSeries(tmpSeries)
  }

  useEffect(() => {
   if (Kota.length > 0 && Kota[0].label !== 'PROVINSI DKI JAKARTA') {
    setVariabel(ReducersBalita.jumlahPerkota[CharacterRemove(Kota[0].label, / /g, '_')])
   }
  }, [Kota])

  useEffect(() => {
    if (ReducersBalita.jumlahProvinsi.jumlah_penduduk !== 0 ) {
      setVariabel(ReducersBalita.jumlahProvinsi);
      
      setKota([{
        label : 'PROVINSI DKI JAKARTA',
        description : ' Jumlah Penduduk '+ NumberSeparator(ReducersKependudukan.jumlahProvinsi.jumlah_penduduk) + 'Jiwa',
        value : '',
      }])
    }

  }, [ReducersBalita])


  return (
    <Fragment>

        <div id='s2-d-publik' className="hero-wrapper mt-3">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content pb-2">
                  <div className="container">
                    <Row>
                      <Col lg="6" className="px-0">
                        <div className="text-start">
                            <div className="">
                              <h3 className="font-weight-bold">
                                  Grafik Sebaran Balita
                              </h3>
                              <p className="mb-3" style={{
                                  fontSize: '12px'
                              }}>
                                  Data Update hasil pendataan 2022
                              </p>
                            </div>
                        </div>
                      </Col>
                      <Col lg="6" md="6">
                      </Col>
                      <Col lg="12" md="12">
                          <Row>
                            <Col lg="3" md="3" className="p-0">
                              <ReactSelect 
                                className="basic-single costume-select mb-2"
                                placeholder="Pilih Kota"
                                defaultValue={Kota}
                                isClearable={true}
                                isRtl={true}
                                isSearchable={true}
                                name="selectKota"
                                options={ReducersKota.kota}
                                onChange={selectKota}
                              />
                            </Col>
                            <Col lg="9" md="9" className="p-0 pl-2">
                              <ReactSelect 
                                isMulti
                                className="basic-single costume-select mb-2"
                                placeholder="Pilih Variabel Pertama"
                                defaultValue={variabelPertama}
                                value={variabelPertama}
                                isClearable={true}
                                isRtl={true}
                                isSearchable={true}
                                name="selectVarPertama"
                                options={ReducersBalita.fieldReactSelected}
                                onChange={(value, action) => reactSelected(value, action)}
                              />
                            </Col>
                          </Row>
                      </Col>
                      <Col xl="6" md="6" className="px-0 pr-1">
                        <Card className="h-100">
                              <CardBody className="pb-0">
                                <ViewApexChart config={configPieChart({
                                  title : Kota,
                                  series : series,
                                  labels : labels
                                })} type="donut" />
                              </CardBody>
                              <CardFooter className="pb-4 font-weight-bold">
                                Hasil Pendataan 2022 - {Kota.length > 0 ? Kota[0].description : ''}
                              </CardFooter>
                          </Card>
                      </Col>
                      <Col xl="6" md="6" className="px-0 pl-1">
                        <Card className="h-100">
                              <CardBody className="pb-0 h-100">
                                <ViewApexChart config={configBarChart({
                                  title : Kota,
                                  series : series,
                                  labels : labels,
                                  isHorizontal : false
                                })} type="bar" />
                              </CardBody>
                              <CardFooter className="pb-4 font-weight-bold">
                                Hasil Pendataan 2022 - {Kota.length > 0 ? Kota[0].description : ''}
                              </CardFooter>
                          </Card>
                      </Col>
                    </Row>
                  </div>
              </div>
            </div>
        </div>

    </Fragment>
  );
};

export default Balita;