import React, { Fragment } from 'react';

import { HeaderUserLogin, SidebarPro } from '../../layout-components';

const UserLayout = props => {
  const { children } = props;

  return (
    <Fragment>
        <div className="app-wrapper app-header-fixed">
          <div>
              <SidebarPro />
          </div>
          <div className="app-main costume-main-user">
            <HeaderUserLogin />
            <div className="app-content">
              <div className="app-content--inner pt-0">
                <div className="app-content--inner__wrapper">
                    {children}
                </div>
              </div>
            </div>
          </div>
        </div>
    </Fragment>
  );
};

export default UserLayout;
