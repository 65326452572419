import React, { useEffect, useState } from 'react';

import { Map, GeoJSON, TileLayer } from 'react-leaflet';
import provGeoJSON from '../../config/GeoJSON/Batas_Wil_DKI_Jakarta.json';
import { useSelector } from 'react-redux';
import { centroid } from '@turf/turf';
// KDPKAB = KODE KABUPATEN / KOTA
// KDCPUM = KODE KECAMATAN
// KDEPUM = KODE KELURAHAN
export default function LeaftletMaps() {
  const { ReducersKota, ReducersKecamatan, ReducersKelurahan } = useSelector(state => state);
  const [polygonMapChild, setPolygonMapChild] = useState([]);
  const [objectNamaWilayah, setObjectNamaWilayah] = useState('WADMKK');
  const [position, setPosition] = useState([-6.19491474765486, 106.82332813512008]);
  const [mapZoom, setMapZoom] = useState(11);

  const getPolygonMapChild = (params) => {
    let filterPolygonMapChild = [];

    if (params.kodeWilayah === 0) { 
      filterPolygonMapChild = params.childWilayah;
    }

    if (params.kodeWilayah !== 0) {
      filterPolygonMapChild = params.childWilayah.features.filter((data) => {
        return data.properties[params.labelKode] == params.kodeWilayah;
      })
    }
    setPolygonMapChild(filterPolygonMapChild);
  }

  const onEachFeatureChild = (features, layer, params) => {
    const namaWilayah = features.properties[params.objectNamaWilayah];
    layer.bindPopup(namaWilayah);
    layer.bindTooltip(namaWilayah, {
      sticky : true
    });
    layer.on({
        click: (e) => {
            console.log(e);
        },
        mousehover : (e) => {
          e.fillOpacity = 1;
        }
    })
    layer.options.fillOpacity = Math.random();
    layer.options.fillColor = "#6359CA"
    layer.options.color = "black"
  }


  useEffect(() => {
    if (ReducersKota.mapSelectedKotaId != null) {
      getPolygonMapChild({
        childWilayah : {}, 
        kodeWilayah : ReducersKota.mapSelectedKotaId,
        labelKode : "KDPKAB"
      })
      
      let filterPolygonParent = provGeoJSON.features.filter((data) => {
        return data.properties.KDPKAB == ReducersKota.mapSelectedKotaId;
      })

      setPosition(centroid(filterPolygonParent[0]).geometry.coordinates.reverse());
      setMapZoom(12.5);
      setObjectNamaWilayah('WADMKC');
    }
  }, [ReducersKota])

  useEffect(() => {
    if (ReducersKecamatan.mapSelectedKecamatanId != null) {
      getPolygonMapChild({
        childWilayah : {}, 
        kodeWilayah : ReducersKecamatan.mapSelectedKecamatanId,
        labelKode : "KDCPUM"
      })
      
      // let filterPolygonParent = kecGeoJSON.features.filter((data) => {
      //   return data.properties.KDCPUM == ReducersKecamatan.mapSelectedKecamatanId;
      // })

      // setPosition(centroid(filterPolygonParent[0]).geometry.coordinates.reverse());
      setMapZoom(14.5);
      setObjectNamaWilayah('WADMKD');
    }
  }, [ReducersKecamatan])

  useEffect(() => {
    if (ReducersKelurahan.mapSelectedKelurahanId != null) {
      getPolygonMapChild({
        childWilayah : {}, 
        kodeWilayah : ReducersKelurahan.mapSelectedKelurahanLabel,
        labelKode : "KELURAHAN"
      })

     
      
      // let filterPolygonParent = kelGeoJSON.features.filter((data) => {
      //   return data.properties.KDEPUM == ReducersKelurahan.mapSelectedKelurahanId;
      // })

      // setPosition(centroid(filterPolygonParent[0]).geometry.coordinates.reverse());
      setMapZoom(15);
      setObjectNamaWilayah('RW');
    }
  }, [ReducersKelurahan])

  useEffect(() => {
    getPolygonMapChild({
      childWilayah : provGeoJSON, 
      kodeWilayah : 0,
    })
  }, [])

  return (
        <>
            <Map 
                center={position} 
                zoom={mapZoom} 
                className="h-100"
                scrollWheelZoom={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
              />
                <GeoJSON 
                  key={position} 
                  data={polygonMapChild} 
                  onEachFeature={(features, layer) => onEachFeatureChild(features, layer, {
                    objectNamaWilayah : objectNamaWilayah
                  })}  
                />
            </Map>
        </>
    )
}



// export LeaftletMaps();