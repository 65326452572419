import React, { Fragment, Component } from 'react';

import clsx from 'clsx';

import { NavLink, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import { Link } from 'react-router-dom';
import { LogoPusdatin } from 'assets/images';

class HeaderLanding extends Component {
  toggleSidebarMobile = () => {
    let { sidebarToggleMobile, setSidebarToggleMobile } = this.props;
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  render() {
    let { sidebarToggleMobile } = this.props;
    return (
      <Fragment>
        <div className="app-header" style={{
          paddingLeft: "87px",
          paddingRight: "87px",
          backgroundColor: "#F2F3F9"
        }}>
          <div className="app-header--pane">
            <button
              className={clsx(
                'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                { 'is-active': sidebarToggleMobile }
              )}
              onClick={this.toggleSidebarMobile}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
              <img src={LogoPusdatin} className="img-fluid" alt="..." width="70%" />
          </div>
          <div className="app-header--pane">
              <Nav>
                <NavItem>
                    <NavLink href="./">
                        Beranda
                    </NavLink>
                </NavItem>
                <UncontrolledDropdown
                    inNavbar
                    nav
                    >
                    <DropdownToggle
                        caret
                        nav
                    >
                        Informasi
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <Link to="#">
                            Berita
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="#">
                            Pengumuman
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="#">
                            Infografis
                            </Link>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown
                    inNavbar
                    nav
                    >
                    <DropdownToggle
                        caret
                        nav
                    >
                        Produk
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <Link to="#">
                            Carik Jakarta
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="./dashboard-public">
                            Dashboard PKSP
                            </Link>
                        </DropdownItem>
                        <DropdownItem>
                            <Link to="./peta-sebaran">
                            Peta Sebaran
                            </Link>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown
                    inNavbar
                    nav
                    >
                    <DropdownToggle
                        caret
                        nav
                    >
                        Layanan Kader Dasawima
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem>
                            <Link to="#">
                            Carik Kader
                            </Link>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                    <NavLink href="./">
                        Tentang
                    </NavLink>
                </NavItem>
                <NavItem className="mr-3">
                    <NavLink href="./">
                        Kontak Kami
                    </NavLink>
                </NavItem>
            </Nav>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLanding);
