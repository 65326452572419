import React from 'react'
import ContentLoader from 'react-content-loader'

const PieChart = props => {
  const style = {
    title : {
      width : "100%"
    },
    numberLegend : {
      width : "10px",
      height : "10px"
    },
    legend : {
      width : "100%",
      height : "10px"
    }
  }
  return (
    <ContentLoader style={{ width: '100%', height: "100%" }} {...props}>
      <rect x="0" y="5" rx="0" ry="0" height="15" style={style.title} />
      <circle cx="100" cy="130" r="100" />
      <rect x="230" y="50" rx="0" ry="0" style={style.numberLegend} />
      <rect x="250" y="50" rx="0" ry="0" style={style.legend} />
      <rect x="230" y="64" rx="0" ry="0" style={style.numberLegend} />
      <rect x="250" y="64" rx="0" ry="0" style={style.legend} />
      <rect x="230" y="78" rx="0" ry="0" style={style.numberLegend} />
      <rect x="250" y="78" rx="0" ry="0" style={style.legend} />
      <rect x="230" y="92" rx="0" ry="0" style={style.numberLegend} />
      <rect x="250" y="92" rx="0" ry="0" style={style.legend} />
    </ContentLoader>
  )
}

export default PieChart