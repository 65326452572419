import { endPoint, getParams } from "../../../config/endPoint";
import { client } from "../../../config/Services";
import { CharacterRemove, ModelBarChart } from "../../../helpers";

const initialState = {
  master: [],
  field : [],
  fieldReactSelected : [],
  jumlahProvinsi : {},
  jumlahPerkota : null,
  barChart : [],
  pieChart : [],
  isNull : 1,
  isError : 0,
}

const count = (prop) => {
  let total = 0
  prop.arr.reduce((key, currentValue) => {
    total += currentValue[prop.obj];
  }, 0);

  return total;
}

const groupBy = (array, key, field) => {
  let tmp = array.reduce((result, currentValue) => {
    if (!result[currentValue[key]]) {
      result[currentValue[key]] = {
        data : [],
      };
    }
    result[currentValue[key]].data.push(currentValue);

    return result;
  }, {}); 

  Object.keys(tmp).map((key, index) => {
    field.map((data, i) => {
      tmp[key] = {
        ...tmp[key],
        [data] : count({arr : tmp[key].data, obj : data})
      }
    })
  });

  return tmp;
};

export default function reducer(state = initialState, action){
  switch (action.type) {
    case 'SET_DATA_BALITA':
        return {
          ...state,
          master : action.payload.data.data,
          isNull : action.isNull
        }
    case 'SET_FIELD':
      let tmpField = [];
      let tmpFieldReactSelect = [];
      Object.keys(state.master.length > 0 ? state.master[0] : []).map((key0, index) => {
        if (["id", "kota", "kecamatan", "kelurahan", "rw", "rt", "createdAt", "updatedAt", "kode_wilayah"].includes(key0) ) {
            return false;
        }

        tmpField.push(key0);
        tmpFieldReactSelect.push({
          label : CharacterRemove(key0, /_/g, ' ').replace('jumlah ', ''),
          value : key0
        });
      })

      return {
        ...state,
        field : tmpField,
        fieldReactSelected : tmpFieldReactSelect
      }
    case 'SET_JUMLAH_PROV':
        let tmpJumlahProvinsi = null;
        state.field.map((data, i) => {
          tmpJumlahProvinsi = {
            ...tmpJumlahProvinsi,
            [data] : count({arr : state.master, obj : data})
          }
        })

        return {
          ...state,
          jumlahProvinsi : {
            ...state.jumlahProvinsi,
            ...tmpJumlahProvinsi
          }
        };
    case 'SET_JUMLAH_KOTA':
        return {
          ...state,
          jumlahPerkota : groupBy(state.master, 'kota', state.field)
        };
    case 'SET_JUMLAH_KECAMATAN':
        return {
          ...state
        };
    case 'SET_JUMLAH_KABUPATEN':
        return {
          ...state
        };
    case 'SET_GRAFIK_BATANG':
      return {
        ...state,
        barChart : ModelBarChart(state, {
            payload : state.jumlahPerkota
        })
      };
    case 'SET_GRAFIK_PIE':
      return {
        ...state,
        pieChart : []
      };
    case 'SET_MAP':
      return {
        ...state
      };
    default:
      break;
  }

  return state;
}

// thunk function
export const fetchMaster = async (dispatch, getState) => {
  const response = await client.get(endPoint.balita.path, {
    params : getParams
  })

  if (response.status === 200) {
    dispatch({ 
      type: 'SET_DATA_BALITA', 
      payload: response,
      isNull : 0 
    })
  } 

  return fetchField(dispatch, getState)
}

const fetchField = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_FIELD',
  })
  return fetchJumlahProv(dispatch, getState)
}

const fetchJumlahProv = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_JUMLAH_PROV', 
  })

  return  fetchJumlahKota(dispatch, getState)
}

const fetchJumlahKota = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_JUMLAH_KOTA', 
  })

  return fetchGrafikBatang(dispatch, getState);
}

const fetchGrafikBatang = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_GRAFIK_BATANG',
  })

  return fetchGrafikPie(dispatch, getState)
}

const fetchGrafikPie = async (dispatch, getState) => {
  dispatch({ 
    type: 'SET_GRAFIK_PIE',
  })
}