import React, { Fragment } from 'react';

const PengajuanData = () => {
  return (
    <Fragment>
        <div className="hero-wrapper">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div id='peta-sebaran' className="bg-composed-wrapper--content">
                    {/* <LivePreviewExample /> */}
                </div>
            </div>
        </div>
        <div className='mb-5' />
    </Fragment>
  );
};

export default PengajuanData;