// kota
import masterServiceWilayah from '../../config/Wilayah';

export default function reducer(
  state = {
    kecamatan: masterServiceWilayah.getKecamatanKemendagri(),
    mapSelectedKecamatanId : null,
    mapSelectedKecamatanLabel : null,
  },
  action
) {
  switch (action.type) {
    case 'SET_MAP_KECAMATAN':
      return {
        ...state,
        mapSelectedKecamatanId: action.selectedKecamatanId,
        mapSelectedKecamatanLabel: action.selectedKecamatanLabel
      };
    default:
      break;
  }
  return state;
}
