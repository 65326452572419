import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import routes from 'config/routes';
import React, { Fragment, Component } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

class SidebarPro extends Component {
  render() {
    return (
      <Fragment>
        <ProSidebar collapsed={true} className="costume-menu-container fixed-top" >
            <SidebarContent>
                <Menu iconShape="circle" className='costume-menu-wrapper' >
                    {routes.map((route, idx) => {
                        return route && route.status ? (
                            !route.children ? <MenuItem
                            key={idx} 
                            icon={<FontAwesomeIcon className='icon-sidebar-pro' icon={route.icon} />}
                            className="costume-menu-item"
                            id={'item-'+idx}
                        >
                            <Link to={route.path}>
                                {route.name}
                            </Link>
                        </MenuItem> : 
                        <SubMenu 
                            key={idx} 
                            id={'item-'+idx} 
                            title={route.name}
                            icon={<FontAwesomeIcon className='icon-sidebar-pro' icon={route.icon} />}
                            className="costume-menu-item"
                        >
                            <MenuItem active={false}>{route.name}</MenuItem>
                            {
                                route.children.map((r, id) => {
                                    return r ? (
                                        <MenuItem 
                                            key={'child'+id}
                                            className="costume-menu-item costume-submenu-item"
                                            icon={<FontAwesomeIcon className='icon-sidebar-pro' icon={r.icon} />}
                                        >
                                            <Link to={r.path}>
                                                {r.name}
                                            </Link>
                                        </MenuItem>
                                    ) : (null);
                                })
                            }
                        </SubMenu>
                        ) : (null);
                    })}
                </Menu>
            </SidebarContent>
            <SidebarFooter className='text-center'>
                @2022
            </SidebarFooter>
        </ProSidebar>
        {routes.map((route, idx) => {
            return route && route.status ? (
                <UncontrolledTooltip
                    key={'tooltip-'+idx}
                    placement="right"
                    container="body"
                    trigger={!route.children ? "hover focus" : ""}
                    target={'item-'+idx}>
                    {route.name}
                </UncontrolledTooltip>
            ) : (null);
        })}
      </Fragment>
    );
  }
}

export default SidebarPro;
