import { CapitalizeEachWord, CharacterRemove } from ".";

export const ModelBarChart = (state, action) => {
    let tmpBarChart = []
    Object.keys(state.master.length > 0 ? state.master[0] : []).map((key0, index) => {
        if (["id", "kota", "kecamatan", "kelurahan", "rw", "rt", "tahun","createdAt", "updatedAt", "kode_wilayah"].includes(key0) ) {
            return false;
        }
        let tmpXValue = [], tmpYValue = [];

        Object.keys(action.payload).map((key, index) => { 
            tmpXValue.push(key.replace(/_/g, ' '));
            tmpYValue.push(action.payload[key][key0]);
        })

        tmpBarChart.push({
            label : CapitalizeEachWord(key0.replace(/_/g, ' ')),
            categories : 'kependudukan',
            xValue : tmpXValue,
            yValue : tmpYValue,
            description : 'Explenation about this chart value'
        })
    })

    return tmpBarChart;
}

export const ModelPieChart = (state, action) => {
    let tmpPieChart = []
    
    return tmpPieChart;
}

export const ModelPiramida = (data) => {
    // let data = props.data;
    let series = [];
    let labels = [];

    let seriesMales = [];
    let seriesFemales = [];
    let tmpCategories = [];

    if (![null, undefined, {}].includes(data)) {
      Object.keys(data).map((key, i) => {
        if (key === 'data') {
          return false;
        }

        let tmp = CharacterRemove(key, /_/g, ' ').replace('jml usia ', '');
        //create Males series
        if (tmp.includes('laki')) {
          seriesMales.push((data[key]) * -1 );
        }

        //create Females series
        if (tmp.includes('perem')) {
          seriesFemales.push(data[key]);
        }
        
        // create categories || labels
        if (tmp.includes('laki')) {
          if (tmp.includes('atau lebih')) {
            tmpCategories.push(tmp.replace(' laki', '').replace(' atau lebih', '+'))

            return false;
          }

          tmpCategories.push(tmp.replace(' laki', '').replace(' ', '-'))
        }
      })


    }

    series = [
        {
            name: 'Laki - Laki',
            data: seriesMales.reverse()
        },
        {
            name: 'Perempuan',
            data: seriesFemales.reverse()
        }
      ]
    
    labels = tmpCategories.reverse()

    return {seriesMales, seriesFemales, series, labels}
}