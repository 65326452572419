
import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';

const ViewApexChart = (props) => {
  const {config, type, height} = props;

  return (
    <Fragment>
        <div id='chart'>
            <Chart options={config.options} series={config.series} type={type} height={height ? height : 'auto'} />
        </div>
    </Fragment>
  );
}

export default ViewApexChart;