import axios from "axios";

export async function client(endpoint, { body, ...customConfig } = {}) {
    if (endpoint === '') {
      return new Error({
        status : 404,
        statusText : 'API BELUM ADA'
      })
    }
    const headers = { 
        'Content-Type'  : 'application/json',
        'ilham'         : '31k1ZLP1V7K1eVNozemNNqSCFTLgYE7s'
    }
  
    const config = {
      method: body ? 'POST' : 'GET',
      url : endpoint,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    }
  
    if (body) {
      config.body = JSON.stringify(body)
    }
  
    let data
    try {
      const response = await axios(config)
      data = await response
      if (response.status === 200) {
        return data
      }
      throw new Error(response.statusText)
    } catch (err) {
      return Promise.reject(err.message ? err.message : data)
    }
}

client.get = (endpoint, customConfig = {}) => {
    return client(endpoint, { ...customConfig, method: 'GET' })
}
  
client.post = (endpoint, body, customConfig = {}) => {
    return client(endpoint, { ...customConfig, body })
}
  

