// kota
import masterServiceWilayah from '../../config/Wilayah';

export default function reducer(
  state = {
    kota: masterServiceWilayah.getKotaKemendagri(),
    mapSelectedKotaId : null,
    mapSelectedKotaLabel : null,
  },
  action
) {
  switch (action.type) {
    case 'SET_MAP_KOTA':
      return {
        ...state,
        mapSelectedKotaId: action.selectedKotaId,
        mapSelectedKotaLabel: action.selectedKotaLabel
      };
    default:
      break;
  }
  return state;
}
