import React, { Fragment, useEffect } from 'react';

import { Row, Col, ListGroup,ListGroupItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LogoCarik, LogoPkk, LogoSimPkk } from "../../assets/images";
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLocationArrow, faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';

function Footer() {
  const history = useHistory();
  const pathname = history.location.pathname;

  useEffect(() => {
    console.log(history.location.pathname);
  }, [])
  return (
    <Fragment>
      
      <div id="section-dua" className="hero-wrapper" style={{
        minHeight: pathname == '/dashboard-public' ? "350px" : "200px",
      }}>
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div className="bg-composed-wrapper--content pb-2 py-lg-5">
            <div className="container">
              <Row>
                <Col lg={pathname == '/dashboard-public' ? "5" : "12" } sm="12">
                  <Row>
                    <Col lg={pathname == '/dashboard-public' ? "12" : "6"} sm="12">
                      <ListGroup horizontal style={{
                        backgroundColor: "none !important"
                      }}>
                        <ListGroupItem className="footer-list border-0">
                          <div className="d-flex w-100">
                            <div className="mr-3">
                              <FontAwesomeIcon icon={faLocationArrow} />
                            </div>
                            <div>
                              <div className="font-weight-bold d-block opacity-8">
                              SEKRETARIAT TIM PENGGERAK PKK PROVINSI DAERAH KHUSUS IBUKOTA JAKARTA
                              </div>
                              <div className="opacity-5">
                              Jl. Kebagusan Raya No.42, RT.1/RW.7, Ragunan, Kec. Ps. Minggu, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12550
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                    <Col lg={pathname == '/dashboard-public' ? "12" : "2"} sm="12">
                      <ListGroup horizontal style={{
                        backgroundColor: "none !important"
                      }}>
                        <ListGroupItem className="footer-list border-0">
                          <div className="d-flex w-100">
                            <div className="mr-3">
                              <FontAwesomeIcon icon={faInstagram} />
                            </div>
                            <div>
                              <div className="font-weight-bold d-block opacity-8">
                                Instagram
                              </div>
                              <div className="opacity-5">
                                @pkkjakarta
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                    <Col lg={pathname == '/dashboard-public' ? "12" : "2"} sm="12">
                      <ListGroup horizontal style={{
                        backgroundColor: "none !important"
                      }}>
                        <ListGroupItem className="footer-list border-0">
                          <div className="d-flex w-100">
                            <div className="mr-3">
                              <FontAwesomeIcon icon={faPhone} />
                            </div>
                            <div>
                              <div className="font-weight-bold d-block opacity-8">
                                Telepon
                              </div>
                              <div className="opacity-5">
                                02178838466
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                    <Col lg={pathname == '/dashboard-public' ? "12" : "2"} sm="12">
                      <ListGroup horizontal style={{
                        backgroundColor: "none !important"
                      }}>
                        <ListGroupItem className="footer-list border-0">
                          <div className="d-flex w-100">
                            <div className="mr-3">
                              <FontAwesomeIcon icon={faMailBulk} />
                            </div>
                            <div>
                              <div className="font-weight-bold d-block opacity-8">
                                Email
                              </div>
                              <div className="opacity-5">
                                tppkkdki@gmail.com
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="app-footer text-black-50" style={{
        height: "6vh"
      }}>
        <div className="app-footer--first">
          <span>TP PKK DKI Jakarta</span> ©
          2022 - SIM PKK
        </div>
        <div className="app-footer--second" style={{
          lineHeight: "3vh"
        }}>
          <Row>
              <Col lg="4" md="4" sm="4" className="text-center">
                <img src={ LogoSimPkk } width="60px" className='img-fluid'/>
              </Col>
              <Col lg="4" md="4" sm="4" className="text-left">
                <img src={ LogoPkk } width="85px" className='img-fluid'/>
              </Col>
              <Col lg="4" md="4" sm="4" className="text-right">
                <img src={ LogoCarik } width="85px" className='img-fluid'/>
              </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
