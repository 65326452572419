import React, { Fragment } from 'react';
import { Footer, HeaderDashboardPublic, SidebarHeader, Side, SidebarMaps } from '../../layout-components';

import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

const PetaSebaranLayout = props => {
  const { children } = props;

  return(
    <Fragment>
        <div className="app-wrapper app-header-fixed">
          <div>
              <SidebarMaps />
          </div>
          <div className="app-main">
            {/* <SidebarHeader /> */}
            <div className="app-content p-0">
              <div className="app-content--inner p-0">
                <div className="app-content--inner__wrapper">
                    {children}
                </div>
              </div>
            </div>
          </div>
        </div>
    </Fragment>
  );
};

PetaSebaranLayout.propTypes = {
  children: PropTypes.node
};

export default PetaSebaranLayout;
