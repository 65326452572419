import { HighLightKependudukan } from 'pages-component';
import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

const Dashboard = () => {
  return (
    <Fragment>
        <div className="hero-wrapper">
            <div className="align-items-center">
                <Row>
                    <Col lg="12" md="12" className="text-center">
                        <h1 className='pt-0 pb-3'>
                            Sistem Informasi Managemen PKK
                        </h1>
                    </Col>
                </Row>
                <HighLightKependudukan />
            </div>
        </div>
        <div className='mb-5' />
    </Fragment>
  );
};

export default Dashboard;