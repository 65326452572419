import axios from "axios";
import { endPoint } from "config/endpoint.services";
import MasterWilayah from 'config/Wilayah/json-wilayah/Master.json';
import { setMessage } from "reducers/ReducersMessage";
import store from 'config/configureStore';

const register = (username, email, password) => {
  return axios.post(endPoint.auth.register, {
    username,
    email,
    password,
  });
};

const login = (formLogin) => {

  return axios
    .post(endPoint.auth.login.path, formLogin)
    .then((response) => {
      if (response.data.token) {
        let resWilayah = MasterWilayah.filter(wilayah => wilayah.value == response.data.kodeWilayah);
        let wilayah = null;

        if (resWilayah.length > 0) {
          if (resWilayah[0].value.toString().length == 2) {
            wilayah = 'PROVINSI';
          }
  
          if (resWilayah[0].value.toString().length == 4) {
            wilayah = 'KOTA';
          }
  
          if (resWilayah[0].value.toString().length == 6) {
            wilayah = 'KECAMATAN';
          }
  
          if (resWilayah[0].value.toString().length == 10) {
            wilayah = 'KELURAHAN';
          }
        }

        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("wilayah", JSON.stringify(resWilayah));
        localStorage.setItem("levelWilayah", JSON.stringify(wilayah));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("wilayah");
  localStorage.removeItem("levelWilayah");
};

export default {
  register,
  login,
  logout,
};