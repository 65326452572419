import { LeaftletMaps } from 'pages-component';
import React, { Fragment } from 'react';

const PetaSebaran = () => {
  return (
    <Fragment>
        <div className="hero-wrapper">
            <div className="flex-grow-1 w-100 d-flex align-items-center" >
                <div id='peta-sebaran' className="bg-composed-wrapper--content">
                    <LeaftletMaps />
                </div>
            </div>
        </div>
    </Fragment>
  );
};

export default PetaSebaran;