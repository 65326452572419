import { LogoCarik, LogoDppapp, LogoPkk } from 'assets/images';
import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

const LogoInstansi = (styleCostume= {}) => {
  return (
    <Fragment>
        <Row style={{
            backgroundColor: "white",
            borderRadius: "15px",
            height: "25px"
        }} className="justify-beetwen" >
            <Col lg="4" md="4" >
                <img src={ LogoDppapp } />
            </Col>
            <Col lg="4" md="4" >
                <img src={ LogoPkk } />
            </Col>
            <Col lg="4" md="4" >
                <img src={ LogoCarik } />
            </Col>
        </Row>
    </Fragment>
  );
};

export default LogoInstansi;