import { lazy } from "react";

// logo instansi
export { default as LogoPusdatin } from "./landing-pages/section-4/pusdatin.svg";
export { default as LogoDppapp} from "./landing-pages/section-4/dppapp.svg";
export { default as LogoPkk} from "./landing-pages/section-4/tp-pkk.svg";
export { default as LogoPusdatinPutih} from "./dashboard-pendataan/logo-pusdatin-putih.png";
export { default as LogoCarik } from "./dashboard-pendataan/logo-carik.svg";
export { default as LogoSimPkk } from "./dashboard-pendataan/logo-sim-pkk.svg";
export { default as LogoSimPkkHeader } from "./dashboard-pendataan/logo-sim-pkk-header.svg";
export { default as LogoSimPkkWhite } from './dashboard-pendataan/logo-sim-pkk-white.svg';

// icon topik data

export { default as IconPancasila } from './topik-data/pancasila.svg';
export { default as IconGotongRoyong } from './topik-data/gotong-royong.svg';
export { default as IconPangan } from './topik-data/pangan.svg';
export { default as IconSandang } from './topik-data/sandang.svg';
export { default as IconRumahTangga } from './topik-data/rumah-tangga.svg';
export { default as IconPendidikanKeterampilan } from './topik-data/pendidikan-keterampilan.svg';
export { default as IconKesehatan } from './topik-data/kesehatan.svg';
export { default as IconKoperasi } from './topik-data/koperasi.svg';
export { default as IconLingkungan } from './topik-data/lingkungan.svg';
export { default as IconPerencanaanSehat } from './topik-data/perencanaan-sehat.svg';
export { default as IconKependudukan } from './topik-data/kependudukan.svg';

// icon wilayah 
export { default as KS } from './landing-pages/logo-wilayah/KS.svg';
export { default as JP } from './landing-pages/logo-wilayah/JP.svg';
export { default as JB } from './landing-pages/logo-wilayah/JB.svg';
export { default as JS } from './landing-pages/logo-wilayah/JS.svg';
export { default as JT } from './landing-pages/logo-wilayah/JT.svg';
export { default as JU } from './landing-pages/logo-wilayah/JU.svg';

// icon pendataan 
export { default as IconKelompok } from './icon-pendataan/icon-kelompok.svg';
export { default as IconBangunan } from './icon-pendataan/icon-bangunan.svg';
export { default as IconKrt } from './icon-pendataan/icon-krt.svg';
export { default as IconKeluarga } from './icon-pendataan/icon-keluarga.svg';
export { default as IconIndividu } from './icon-pendataan/icon-individu.svg';

export { default as HpTengah } from "./landing-pages/section-4/hp-tengah.png";
export { default as SampleImage } from "./dashboard-pendataan/sample-image-dashboard.jpg";
export { default as HpKiri } from "./landing-pages/section-4/hp-kiri.png";

export { default as SscarikFront } from "./landing-pages/ss-aplikasi-front.svg";
export { default as SscarikBack } from "./landing-pages/ss-aplikasi-back.svg";
export { default as LoadingIo } from './components/loading-io.svg';