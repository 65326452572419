import ThemeOptions from './ThemeOptions';
import ReducersKota from './ReducersKota';
import ReducersKecamatan from './ReducersKecamatan';
import ReducersKelurahan from './ReducersKelurahan';
import { ReducersKependudukan, ReducersAgama, ReducersPernikahan, ReducersTidakBekerja, ReducersPiramidaPenduduk, ReducersBalita, ReducersLansia, ReducersAktaWarga } from './ReducersKependudukan';
import { ReducersPutusSekolah } from "./ReducersPendidikan";
import { ReducersBumil, ReducersDisabilitas, ReducersHivTb, ReducersKankerPerempuan, ReducersKesehatanBayi } from "./ReducersKesehatan";
import { ReducersKesehatanMsy, ReducersPusWus } from "./ReducersKontrasepsi";
import { ReducersLingkunganRawan } from "./ReducersLingkungan";
import { ReducersPengolahanSampah, ReducersRumahPTPLahan, ReducersRumahPtpPerikanan, ReducersRumahPtpPeternakan, ReducersRumahSehat, ReducersSumberAir, ReducersUp2kIndustri } from "./ReducersPerumahan";
import { ReducersKader, ReducersWargaAktif } from "./ReducersPkk";
import { ReducersProgramPemerintah } from "./ReducersProgramPemerintah";
import { ReducersVariabel } from "./ReducersVariabel";
import { ReducersAuth } from './ReducersAuth';
import { ReducersMessage } from './ReducersMessage';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  ThemeOptions, ReducersKota, ReducersKecamatan, ReducersKelurahan, ReducersKependudukan, ReducersAgama, ReducersPernikahan, ReducersTidakBekerja,
  ReducersPiramidaPenduduk, ReducersBalita, ReducersLansia, ReducersAktaWarga, ReducersPutusSekolah, ReducersBumil, ReducersDisabilitas, ReducersHivTb, ReducersKankerPerempuan, ReducersKesehatanBayi, ReducersKesehatanMsy, ReducersPusWus, ReducersLingkunganRawan, ReducersPengolahanSampah, ReducersRumahPTPLahan, ReducersRumahPtpPerikanan, ReducersRumahPtpPeternakan, ReducersRumahSehat, ReducersSumberAir, ReducersUp2kIndustri, ReducersKader, ReducersWargaAktif, ReducersProgramPemerintah, ReducersVariabel, ReducersAuth, ReducersMessage
})

export default rootReducer
