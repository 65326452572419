import { NumberSeparator } from 'helpers'
export const OptionsPieChart = ({labels, title}) => {
    const options = {
        chart: {
          width: 280,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          },
          donut: {
            labels: {
              show: true,
            }
          }
        },
        labels: labels ? labels : [],
        dataLabels: {
          enabled: true
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function(val, opts) {
            return val + " - " + NumberSeparator(opts.w.globals.series[opts.seriesIndex])
          }
        },
        title: {
          text: title ? title.label : 'Silahkan Pilih Sumber Data Dahulu'
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }

      return options;
}