import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
  } from "../TypeAction";

import AuthService from "../../config/Services/auth.service";
import MasterWilayah from 'config/Wilayah/json-wilayah/Master.json';


  export const register = (username, email, password) => (dispatch) => {
    return AuthService.register(username, email, password).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

  export const login = (formLogin) => (dispatch) => {
    return AuthService.login(formLogin).then(
      (data) => {
        let wilayah = MasterWilayah.filter(wilayah => wilayah.value == data.kodeWilayah);
        let levelWilayah = null;

        if (wilayah.length > 0) {
          if (wilayah[0].value.toString().length == 2) {
            levelWilayah = 'PROVINSI';
          }
  
          if (wilayah[0].value.toString().length == 4) {
            levelWilayah = 'KOTA';
          }
  
          if (wilayah[0].value.toString().length == 6) {
            levelWilayah = 'KECAMATAN';
          }
  
          if (wilayah[0].value.toString().length == 10) {
            levelWilayah = 'KELURAHAN';
          }
        }

        

        dispatch({
          type: LOGIN_SUCCESS,
          payload: { 
            user: data,
            wilayah : wilayah,
            levelWilayah : levelWilayah 
          },
        });
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: {
            message : message,
            code : error.response ? error.response.status ? error.response.status : error.code ? error.code : 511 : 511
          },
        });

        return Promise.reject();
      }
    );
  };
  
  export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
      type: LOGOUT,
    });
  };