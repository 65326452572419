import { IconPancasila } from 'assets/images';
import { routesNavTopPublic } from 'config/routes';
import React, { Fragment, useState } from 'react';
import { Link, Route, Router, useHistory } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Card, CardBody } from 'reactstrap';

const MegaMenu = (props) => {
    const {isOpen, setModal} = props;

    const history = useHistory();
    const modalLink = (path) => {
        setModal(!isOpen);
        history.push(path);
    }
  return (
    <Fragment>
        <Modal isOpen={isOpen}  fullscreen={true}>
            <ModalHeader className="container">
                Topik Data
            </ModalHeader>
            <ModalBody className="container">
                <Row>
                    { routesNavTopPublic.map((data, i) => {
                        return <Col lg="3" className="px-2 py-2" key={i}>
                            <Link onClick={() => modalLink(data.path)}>
                                <Card className="border-warning h-100">
                                    <CardBody className="m-0 p-2">
                                        <div className='ml-3 mt-2 d-flex'>
                                            <img src={data.icon} height="50px" />
                                            <h6 className="font-weight-bold ml-4">
                                                {data.name}
                                            </h6>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    }) }
                </Row>
            </ModalBody>
        </Modal>
    </Fragment>
  );
};

export default MegaMenu;