const url = 'https://dev-carik.jakarta.go.id/api/dashboard';
// const url = 'https://10.50.41.12:8000/dashboard';
export const getParams = {
    page : 0,
    size : 267,
    kota : '',
    kecamatan : '',
    kelurahan : ''
}

export const endPoint = {
    kependudukan   : {
        path : url + "/penduduk-dki-jakarta",
    },
    piramidaPenduduk : {
        path : url + "/piramida-penduduk",
    },
    agama : {
        path : url + "/agama",
    },
    balita : {
        path : url + "/jumlah-balita",
    },
    tidakBekerja : {
        path : url + "/tidak-bekerja",
    },
    statusPernikahan : {
        path : url + "/status-pernikahan",
    },
    aktaWarga : {
        path : url + "/akta-warga",
    },
    lansia : {
        path : url + "/lansia",
    },
    pendidikan : {
        putusSekolah : {
            path : url + "/putus-sekolah"
        }
    },
    kontrasepsi : {
        pusWus : {
            path : url + "/pus-wus"
        },
        kesehatanMsy : {
            path : url + "/kesehatan-masyarakat"
        }
    },
    kesehatan : {
        bumil :{
            path : url + "/ibuhamil"
        },
        kesehatanBayi :{
            path : url + "/kesehatan-bayi"
        },
        disabilitas :{
            path : url + "/disabilitas"
        },
        kankerWanita : {
            path : url + "/kanker-wanita"
        },
        hivTb : {
            path : url + '/jumlah-hiv-tb'
        }
    },
    lingkungan :{
        lingkunganRawan : {
            path : url + '/lingkungan-rawan'
        }
    },
    perumahan :{
        sumberAir :{
            path : url + '/sumber-air-keluarga'
        },
        pengolahanSampah :{
            path : url + '/pengolahan-sampah'
        },
        rumahSehat : {
            path : url + '/rumah-sehat'
        },
        rumahPtp : {
            path : url + '/rumah-ptp'
        },
        rumahPtpPerikanan :{
            path : url + '/ptp-perikanan'
        },
        rumahPtpPeternakan :{
            path : url + '/ptp-peternakan'
        },
        rumahUp2kIndustri :{
            path : url + '/rumah-up2k-industri'
        },
        karakteristikRumah :{
            path : url + '/karakteristik-rumah'
        }
    },
    pkk : {
        wargaAktifBerkegiatan :{
            path : url + '/warga-aktif-kegiatan'
        },
        jumlahPkk :{
            path : url + '/jumlah-kader-pkk'
        }
    },
    programPemerintah : {
        programPemerintah : {
            path : url + '/program-pemerintah'
        }
    }
}
