import { NumberSeparator } from 'helpers'
export const OptionsHorizontalBarChart = (props) => {
    const options = {
        title: {
          text: props.title.length > 0 ? props.title[0].label : 'Silahkan Pilih Sumber Data Dahulu'
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return NumberSeparator(val);
          },
          style: {
            fontSize: '12px',
            colors: ['#222'],
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
          },
        },
        colors : ['#FFD775'],
        xaxis: {
          categories: props.labels,
        }
    };

    return options;
}