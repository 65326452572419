import React, { Fragment, Component } from 'react';

import { Link } from 'react-router-dom';
import {  Nav, NavItem, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LogoInstansi } from 'layout-components';

class HeaderUserLogin extends Component {
  render() {
    return (
      <Fragment>
        <div className="app-sidebar--header pl-3" style={{
            backgroundColor: "#FFFFFF"
            }}>
            <Nav>
                <NavItem>
                    <FontAwesomeIcon icon={['far', 'folder-open']} />  Pendataan Keluarga Satu Pintu <br />
                    <small>
                        Temukan informasi terkini mengenai Keluarga dan Individu di DKI Jakarta
                    </small>
                </NavItem>
            </Nav>
            <Link to="./auth">
                    <LogoInstansi styeCostume={{
                        height: "25px"
                    }} />
            </Link>
        </div>
        <div>
            <Breadcrumb listTag="div">
                <BreadcrumbItem
                    active
                    // href="#"
                    tag="a"
                >
                User
                </BreadcrumbItem>
                <BreadcrumbItem
                    active
                    tag="span"
                >
                Dashboard
                </BreadcrumbItem>
            </Breadcrumb>
        </div>
      </Fragment>
    );
  }
}

export default HeaderUserLogin;
