const url = process.env.REACT_APP_ENDPOINT_API;
export const urlLocalKibana = process.env.REACT_APP_ENDPOINT_KIBANA ? process.env.REACT_APP_ENDPOINT_KIBANA : 'https://localhost:5601/';
export const urlLocalElastic = process.env.REACT_APP_ENDPOINT_ELASTIC ? process.env.REACT_APP_ENDPOINT_ELASTIC : 'https://localhost:9200/';
export const urlLocalPksp = process.env.REACT_APP_ENDPOINT_PKSP ? process.env.REACT_APP_ENDPOINT_PKSP : 'https://localhost:3082/';
export const urlLocalWilayah = process.env.REACT_APP_ENDPOINT_WILAYAH;
export const urlLocal = 'https://localhost:9200/';

export const getParams = {
    page : 0,
    size : 267,
    kota : '',
    kecamatan : '',
    kelurahan : ''
}

export const endPoint = {
    dashboard : {
        path : url + "/dashboard/pendataan-2021",
        master_path : url + "/dashboard"
    },
    dashboard_bps : {
        path : url + "/dashboard/bps/",
        master_code : url + "/dashboard/dashboard/master-code"
    },
    bnba : {
        path : url + "/bnba",
    },
    topikData : {
        path : url + "/setup-dashboard/dashboard/",
    },
    setupDashboard : {
        path : url + "/setup-dashboard",
    },
    auth : {
        login : {
            path : url + "/auth/signin"
        },
        register : {
            path : url + ""
        }
    },
    kependudukan   : {
        path : url + "/dashboard/penduduk-dki-jakarta",
    },
    piramidaPenduduk : {
        path : url + "/dashboard/piramida-penduduk",
    },
    agama : {
        path : url + "/dashboard/agama",
    },
    balita : {
        path : url + "/dashboard/jumlah-balita",
    },
    tidakBekerja : {
        path : url + "/dashboard/tidak-bekerja",
    },
    statusPernikahan : {
        path : url + "/dashboard/status-pernikahan",
    },
    aktaWarga : {
        path : url + "/dashboard/akta-warga",
    },
    lansia : {
        path : url + "/dashboard/lansia",
    },
    pendidikan : {
        putusSekolah : {
            path : url + "/dashboard/putus-sekolah"
        }
    },
    kontrasepsi : {
        pusWus : {
            path : url + "/dashboard/pus-wus"
        },
        kesehatanMsy : {
            path : url + "/dashboard/kesehatan-masyarakat"
        },
        pengunaanKb : {
            path : url + "/dashboard/kb"
        }
    },
    kesehatan : {
        bumil :{
            path : url + "/dashboard/ibuhamil"
        },
        kesehatanBayi :{
            path : url + "/dashboard/kesehatan-bayi"
        },
        disabilitas :{
            path : url + "/dashboard/disabilitas"
        },
        kankerWanita : {
            path : url + "/dashboard/kanker-wanita"
        },
        hivTb : {
            path : url + '/dashboard/jumlah-hiv-tb'
        }
    },
    lingkungan :{
        lingkunganRawan : {
            path : url + '/dashboard/lingkungan-rawan'
        }
    },
    perumahan :{
        sumberAir :{
            path : url + '/dashboard/sumber-air-keluarga'
        },
        pengolahanSampah :{
            path : url + '/dashboard/pengolahan-sampah'
        },
        rumahSehat : {
            path : url + '/dashboard/rumah-sehat'
        },
        rumahPtp : {
            path : url + '/dashboard/rumah-ptp'
        },
        rumahPtpPerikanan :{
            path : url + '/dashboard/ptp-perikanan'
        },
        rumahPtpPeternakan :{
            path : url + '/dashboard/ptp-peternakan'
        },
        rumahUp2kIndustri :{
            path : url + '/dashboard/rumah-up2k-industri'
        },
        karakteristikRumah :{
            path : url + '/dashboard/karakteristik-rumah'
        }
    },
    pkk : {
        wargaAktifBerkegiatan :{
            path : url + '/dashboard/warga-aktif-kegiatan'
        },
        jumlahPkk :{
            path : url + '/dashboard/jumlah-kader-pkk'
        }
    }
}
