import React, { Fragment, useEffect, useState } from 'react';
import { ApexChart } from 'pages-component';
import { GrafikLayout } from 'layout';
import { configPieChart } from "helpers";
import { HeaderTopikData } from '../../../layout-components';
import { OptionsVertikalBarChart, OptionsPieChart } from 'helpers/OptionChart';
import { SeriesVertikalBarChart, SeriesPieChart } from 'helpers/SeriesChart';

const PendidikanDanKeterampilan = (props) => {
  const { pagesTitle, pagesDescriptions } = props;
  const [titleChart, setTitleChart] = useState(null);
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);

  const runSetTitleChart = (data) => {
    setTitleChart(data)
  }

  const runSetSeries = (data) => {
    setSeries(data)
  }

  const runSetLabels = (data) => {
    setLabels(data)
  }

  return (
    <Fragment>
        <HeaderTopikData 
            pagesTitle = { pagesTitle }
            pagesDescriptions = { pagesDescriptions }
        />
        <GrafikLayout
            title="Grafik Tidak Sekolah dan Putus Sekolah"
            series = {series}
            setSeries = {runSetSeries}
            labels = {labels}
            setLabels = {runSetLabels}
            titleChart = {titleChart}
            setTitleChart = {runSetTitleChart}
        >
            <ApexChart
                {...GrafikLayout} 
                config={{
                    options : OptionsVertikalBarChart({
                        labels : labels,
                        title : titleChart
                    }),
                    series : SeriesVertikalBarChart({
                        series : series
                    }),
                }}
                type="bar"
            />
            <ApexChart
                {...GrafikLayout} 
                config={{
                    options : OptionsPieChart({
                        labels : labels,
                        title : titleChart
                    }),
                    series : SeriesPieChart({
                        series : series
                    }),
                }}
                type="donut"
            />
        </GrafikLayout>
        <div className='mb-5' />
    </Fragment>
  );
};

export default PendidikanDanKeterampilan;