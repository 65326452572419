import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';

const HeaderTopikData = ({pagesTitle, pagesDescriptions}) => {
    return (
      <Fragment>
        <div id='section-satu' className="hero-wrapper pt-3 pb-3 bg-gradient-primary">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content pb-2 pt-2">
                  <div className="container text-white">
                  <Row>
                      <Col lg="6" className="px-0">
                      <div className="text-start">
                          
                          <div className="">
                          <h3 className="font-weight-bold">
                              {pagesTitle}
                          </h3>
                          <p className="mb-3" style={{
                              fontSize: '12px'
                          }}>
                              {pagesDescriptions}
                          </p>
                          </div>
                      </div>
                      </Col>
                      <Col lg="6" className="px-0 mx-auto d-flex align-items-center pl-5">
                      
                      </Col>
                  </Row>
                  </div>
              </div>
              </div>
        </div>
      </Fragment>
    );
}

export default HeaderTopikData;
