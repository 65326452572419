import { IconGotongRoyong, IconKependudukan, IconKesehatan, IconKoperasi, IconLingkungan, IconPancasila, IconPangan, IconPendidikanKeterampilan, IconRumahTangga, IconSandang } from "assets/images";
import React from "react";

const Dashboard = React.lazy(() => import("../pages-user/Dashboard"));

const routes = [
    { 
        path: '/user/dashboard', 
        exact: true, 
        status : true,
        name: 'Dashboard', 
        icon: ['fas', 'home'],
        component: Dashboard 
    },
    { 
        path: '/user/analisa', 
        exact: true, 
        name: 'Analisa', 
        status : false,
        icon: ['fas', 'chart-bar'],
        children : [
            {
                path: '/user/analisa/kependudukan', 
                exact: true, 
                name: 'Kependudukan', 
                icon: ['fas', 'users'],
                component: Dashboard
            },
            {
                path: '/user/analisa/pendidikan', 
                exact: true, 
                name: 'Pendidikan', 
                icon: ['fas', 'graduation-cap'],
                component: Dashboard
            },
            {
                path: '/user/analisa/ekonomi', 
                exact: true, 
                name: 'Ekonomi', 
                icon: ['fas', 'briefcase'],
                component: Dashboard
            },
            {
                path: '/user/analisa/kesehatan', 
                exact: true, 
                name: 'Kesehatan', 
                icon: ['fas', 'medkit'],
                component: Dashboard
            },
            {
                path: '/user/analisa/lingkungan', 
                exact: true, 
                name: 'Lingkungan', 
                icon: ['fas', 'tree'],
                component: Dashboard
            },
            {
                path: '/user/analisa/kontrasepsi', 
                exact: true, 
                name: 'Kontrasepsi', 
                icon: ['fas', 'venus-mars'],
                component: Dashboard
            }
        ]
    },
    { 
        path: '/user/crostab', 
        exact: true, 
        name: 'Data Visualisasi', 
        status : true,
        icon: ['fa', 'chart-area'],
        children : [
            {
                path: '/user/crostab/kependudukan', 
                exact: true, 
                name: 'Kependudukan', 
                icon: ['fas', 'users'],
                component: Dashboard
            },
            {
                path: '/user/crostab/pendidikan', 
                exact: true, 
                name: 'Pendidikan', 
                icon: ['fas', 'graduation-cap'],
                component: Dashboard
            },
            {
                path: '/user/crostab/ekonomi', 
                exact: true, 
                name: 'Ekonomi', 
                icon: ['fas', 'briefcase'],
                component: Dashboard
            },
            {
                path: '/user/crostab/kesehatan', 
                exact: true, 
                name: 'Kesehatan', 
                icon: ['fas', 'medkit'],
                component: Dashboard
            },
            {
                path: '/user/crostab/lingkungan', 
                exact: true, 
                name: 'Lingkungan', 
                icon: ['fas', 'tree'],
                component: Dashboard
            },
            {
                path: '/user/crostab/kontrasepsi', 
                exact: true, 
                name: 'Kontrasepsi', 
                icon: ['fas', 'venus-mars'],
                component: Dashboard
            }
        ]
    },
    { 
        path: '/user/pengajuan-data', 
        exact: true, 
        name: 'Pengajuan Data', 
        status : true,
        icon: ['far', 'paper-plane'],
        component: Dashboard 
    },
    { 
        path: '/user/profile', 
        exact: true, 
        name: 'Profile', 
        status : true,
        icon: ['far', 'user-circle'],
        component: Dashboard 
    },
    { 
        path: '/#', 
        exact: true, 
        name: 'Keluar', 
        status : true,
        icon: ['fa', 'lock'],
    },
]

export const routesNavTopPublic = [
    { 
        path: '/dp/penghayatan-pancasila', 
        exact: true, 
        name: 'Penghayatan dan Pengamalan Pancasila', 
        status : true,
        icon: IconPancasila,
    },
    { 
        path: '/dp/gotong-royong', 
        exact: true, 
        name: 'Gotong Royong', 
        status : true,
        icon: IconGotongRoyong,
    },
    { 
        path: '/dp/pangan', 
        exact: true, 
        name: 'Pangan', 
        status : true,
        icon: IconPangan,
    },
    { 
        path: '/dp/sandang', 
        exact: true, 
        name: 'Sandang', 
        status : true,
        icon: IconSandang,
    },
    { 
        path: '/dp/perumahan-rumah-tangga', 
        exact: true, 
        name: 'Perumahan dan Tata Laksana Rumah Tangga', 
        status : true,
        icon: IconRumahTangga,
    },
    { 
        path: '/dp/pendidikan-keterampilan', 
        exact: true, 
        name: 'Pendidikan Dan Keterampilan', 
        status : true,
        icon: IconPendidikanKeterampilan,
    },
    { 
        path: '/dp/kesehatan', 
        exact: true, 
        name: 'Kesehatan', 
        status : true,
        icon: IconKesehatan,
    },
    { 
        path: '/dp/kehidupan-berkoperasi', 
        exact: true, 
        name: 'Pengembangan Kehidupan Berkoperasi', 
        status : true,
        icon: IconKoperasi,
    },
    { 
        path: '/dp/kelestarian-lingkungan-hidup', 
        exact: true, 
        name: 'Kelestarian Lingkungan Hidup', 
        status : true,
        icon: IconLingkungan,
    },
    { 
        path: '/dp/kependudukan', 
        exact: true, 
        name: 'Kependudukan', 
        status : true,
        icon: IconKependudukan,
    },
]

export const routesManajemenMenu = [
    { 
        path: 'https://carik.jakarta.go.id/monitoring/v3', 
        externalUrl: true, 
        name: 'Kader Dawis', 
        status : true,
    },
    { 
        path: '#', 
        isDisabled :true,
        externalUrl: true, 
        name: 'Kader PKK', 
        status : true,
    },
    { 
        path: 'https://carik.jakarta.go.id/monitoring/v2', 
        externalUrl: true, 
        name: 'Validasi/Approval Kader', 
        status : true,
    },
    { 
        path: 'https://carik.jakarta.go.id/monitoring/v3', 
        externalUrl: true, 
        name: 'Kelompok Dawis', 
        status : true,
    },
]

export const routesMonirotingMenu = [
    { 
        path: 'https://carik.jakarta.go.id/monitoring/v2', 
        externalUrl: true, 
        name: 'Foto Bangunan', 
        status : true,
    },
    { 
        path: '#', 
        isDisabled :true,
        externalUrl: true, 
        name: 'Pendataan Tematik', 
        status : true,
    },
    { 
        path: '#', 
        externalUrl: true, 
        isDisabled :true,
        name: 'Share Link Task Kader', 
        status : true,
    },
    { 
        path: '#', 
        isDisabled :true,
        externalUrl: true, 
        name: 'Lampid', 
        status : true,
    },
    { 
        path: '#', 
        externalUrl: true, 
        isDisabled :true,
        name: 'Laporan PKK', 
        status : true,
    },
    { 
        path: '#', 
        externalUrl: true, 
        isDisabled :true,
        name: 'Entry Aktivitas', 
        status : true,
    },
    { 
        path: 'https://carik.jakarta.go.id/monitoring/v3', 
        externalUrl: true, 
        name: 'Monitoring Ruang Belajar PKK ', 
        status : true,
    },
]

export default routes;
