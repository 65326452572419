import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

// Layout Blueprints

import { LandingLayout, DashboardPublicLayout } from './layout';

import { GotongRoyong, KelestarianLingkunganHidup, Kependudukan, Kesehatan, Pangan, PendidikanDanKeterampilan, PengembanganKehidupanBerkoperasi, PenghayatanDanPengamalanPancasila, PerumahanDanTataLaksanaRumahTangga, Sandang } from 'pages-public/TopikDataPublik';
import LoginDashboard from 'pages-public/LoginDashboard';
import PetaSebaran from 'pages-public/PetaSebaran';
import PetaSebaranLayout from 'layout/PetaSebaranLayout';
import UserLayout from 'layout/UserLayout';
import { AnalisaKependudukan, Dashboard, PengajuanData } from 'pages-user';
import { LogoSimPkk } from 'assets/images';
const DashboardPublic = lazy(() => import('./pages-public/DashboardPublic'));
const LandingPage = lazy(() => import('./pages-public/LandingPage'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'backIn',
    duration: 0.4
  };

  return (
    <AnimatePresence>
      <Suspense
        fallback={
          <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
            <div className="w-50 mx-auto">
              <img src={LogoSimPkk} />
            </div>
          </div>
        }>
        <Switch>
          <Redirect exact from="/" to="/dashboard-public" />
          <Route path={['/LandingPage']}>
            <LandingLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/LandingPage" component={LandingPage} />
                </motion.div>
              </Switch>
            </LandingLayout>
          </Route>

          <Route path={['/auth']}>
            <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/auth" component={LoginDashboard} />
                </motion.div>
              </Switch>
          </Route>

          <Route path={['/peta-sebaran']}>
              <PetaSebaranLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/peta-sebaran" component={PetaSebaran} />
                  </motion.div>
                </Switch>
              </PetaSebaranLayout>
          </Route>

          <Route 
            path={[
              '/dashboard-public',
              '/dp/kependudukan',
              '/dp/penghayatan-pancasila',
              '/dp/gotong-royong',
              '/dp/pangan',
              '/dp/sandang',
              '/dp/perumahan-rumah-tangga',
              '/dp/pendidikan-keterampilan',
              '/dp/kesehatan',
              '/dp/kehidupan-berkoperasi',
              '/dp/kelestarian-lingkungan-hidup',
            ]}>
              <DashboardPublicLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/dashboard-public" component={DashboardPublic} />
                    <Route path="/dp/kependudukan" component={ Kependudukan } />
                    <Route path="/dp/penghayatan-pancasila" component={ PenghayatanDanPengamalanPancasila } />
                    <Route path="/dp/gotong-royong" component={ GotongRoyong } />
                    <Route path="/dp/pangan" component={ Pangan } />
                    <Route path="/dp/sandang" component={ Sandang } />
                    <Route path="/dp/perumahan-rumah-tangga" component={ PerumahanDanTataLaksanaRumahTangga } />
                    <Route 
                      path="/dp/pendidikan-keterampilan"
                      render={(props) => <PendidikanDanKeterampilan {...props}  
                        pagesTitle = "Dashboard Topik Pendidikan dan Keterampilan"
                        pagesDescriptions = "Bagian ini menjelaskan data terkait aspek pendidikan yang terdata melalui aplikasi Carik Jakarta. Data yang ditampilkan pada bagian ini terutama jumlah anak usia sekolah. "
                      />} 
                    />
                    <Route path="/dp/kesehatan" component={ Kesehatan } />
                    <Route path="/dp/kehidupan-berkoperasi" component={ PengembanganKehidupanBerkoperasi } />
                    <Route path="/dp/kelestarian-lingkungan-hidup" component={ KelestarianLingkunganHidup } />
                  </motion.div>
                </Switch>
              </DashboardPublicLayout>
          </Route>

          <Route 
            path={[
              '/user/dashboard',
              '/user/profile',
              '/user/pengajuan-data',
              '/user/analisa/kependudukan',
            ]}>
              <UserLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/user/dashboard"  component={ Dashboard } />
                    <Route path="/user/profile"  component={ Dashboard } />
                    <Route path="/user/pengajuan-data" component={ PengajuanData } />
                    <Route path="/user/analisa/kependudukan" component={ AnalisaKependudukan } />
                  </motion.div>
                </Switch>
              </UserLayout>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
