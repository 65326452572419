import React, { Component } from 'react';
import store from './config/configureStore';
import { Provider, useDispatch } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import "swiper/swiper.scss";

import { HashRouter } from 'react-router-dom';
import { fetchKependudukan } from './reducers/ReducersKependudukan/DataPenduduk';
import { fetchMaster as fetchAgama } from './reducers/ReducersKependudukan/DataAgama';
import { fetchMaster as fetchPernikahan } from './reducers/ReducersKependudukan/DataPernikahan';
import { fetchMaster as fetchPekerjaan } from './reducers/ReducersKependudukan/DataPekerjaan';
import { fetchMaster as fetchPiramidaPenduduk } from './reducers/ReducersKependudukan/DataPiramidaPenduduk';
import { fetchMaster as fetchBalita } from './reducers/ReducersKependudukan/DataBalita';
import { fetchMaster as fetchLansia } from './reducers/ReducersKependudukan/DataLansia';
import { fetchMaster as fetchAktaWarga } from './reducers/ReducersKependudukan/DataAktaWarga';
import { fetchMaster as fetchPutusSekolah } from './reducers/ReducersPendidikan/DataPutusSekolah';
import { fetchMaster as fetchBumil } from "./reducers/ReducersKesehatan/DataBumil";
import { fetchMaster as fetchDisabilitas } from "./reducers/ReducersKesehatan/DataDisabilitas";
import { fetchMaster as fetchHivTb } from "./reducers/ReducersKesehatan/DataHivTb";
import { fetchMaster as fetchKankerPerempuan } from "./reducers/ReducersKesehatan/DataKankerPerempuan";
import { fetchMaster as fetchKesehatanBayi } from "./reducers/ReducersKesehatan/DataKesehatanBayi";
import { fetchMaster as fetchKesehatanMsy } from "./reducers/ReducersKontrasepsi/DataKesehatanMsy";
import { fetchMaster as fetchPusWus } from "./reducers/ReducersKontrasepsi/DataPusWus";
import { fetchMaster as fetchLingkunganRawan } from "./reducers/ReducersLingkungan/DataLingkunganRawan";
import { fetchMaster as fetchPengolahanSampah } from "./reducers/ReducersPerumahan/DataPengolahanSampah";
import { fetchMaster as fetchLahan } from "./reducers/ReducersPerumahan/DataRumahPtpLahan";
import { fetchMaster as fetchPerikanan } from "./reducers/ReducersPerumahan/DataRumahPtpPerikanan";
import { fetchMaster as fetchPeternakan } from "./reducers/ReducersPerumahan/DataRumahPtpPeternakan";
import { fetchMaster as fetchRumahSehat } from "./reducers/ReducersPerumahan/DataRumahSehat";
import { fetchMaster as fetchRumahIndustri } from "./reducers/ReducersPerumahan/DataRumahUp2kIndustri";
import { fetchMaster as fetchSumberAir } from "./reducers/ReducersPerumahan/DataSumberAir";
import { fetchMaster as fetchDataKader } from "./reducers/ReducersPkk/DataKader";
import { fetchMaster as fetchWargaAktif } from "./reducers/ReducersPkk/DataWargaAktif";

// const store = configureStore();
store.dispatch(fetchKependudukan)
store.dispatch(fetchAgama)
store.dispatch(fetchPernikahan)
store.dispatch(fetchPekerjaan)
store.dispatch(fetchPiramidaPenduduk)
store.dispatch(fetchBalita)
store.dispatch(fetchLansia)
store.dispatch(fetchAktaWarga)
store.dispatch(fetchPutusSekolah)
store.dispatch(fetchBumil)
store.dispatch(fetchDisabilitas)
store.dispatch(fetchHivTb)
store.dispatch(fetchKankerPerempuan)
store.dispatch(fetchKesehatanBayi)
store.dispatch(fetchKesehatanMsy)
store.dispatch(fetchPusWus)
store.dispatch(fetchLingkunganRawan)
store.dispatch(fetchPengolahanSampah)
store.dispatch(fetchLahan)
store.dispatch(fetchPerikanan)
store.dispatch(fetchPeternakan)
store.dispatch(fetchRumahSehat)
store.dispatch(fetchRumahIndustri)
store.dispatch(fetchSumberAir)
store.dispatch(fetchDataKader)
store.dispatch(fetchWargaAktif)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;

