import { ModelPiramida, NumberSeparator } from "helpers";

export const configBarChart = (props) => {
    const optionsHorizontal = {
        title: {
          text: props.title.length > 0 ? props.title[0].label : 'Silahkan Pilih Sumber Data Dahulu'
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return NumberSeparator(val);
          },
          style: {
            fontSize: '12px',
            colors: ['#222'],
          },
        },
        plotOptions: {
          bar: {
            horizontal: props.isHorizontal,
            borderRadius: 4,
          }
        },
        colors : ['#FFD775'],
        xaxis: {
          categories: props.labels,
        }
    };
    
    const optionsVertical = {
      chart: {
        height: 400,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return NumberSeparator(val);
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: props.labels,
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return NumberSeparator(val);
          }
        }
      
      },
      colors : ['#FFD775'],
      title: {
        text: props.title.length > 0 ? props.title[0].label : 'Silahkan Pilih Sumber Data Dahulu',
      }
    };

    const series = [
      {
        data: props.series
      }
    ];
    
    let options = props.isHorizontal ? optionsHorizontal : optionsVertical;

    return { options, series }
}

export const configPieChart = (props) => {
    const options = {
      chart: {
        width: 280,
        type: 'donut',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        },
        donut: {
          labels: {
            show: true,
          }
        }
      },
      labels: props.labels,
      dataLabels: {
        enabled: true
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        formatter: function(val, opts) {
          return val + " - " + NumberSeparator(opts.w.globals.series[opts.seriesIndex])
        }
      },
      title: {
        text: props.title.length > 0 ? props.title[0].label : 'Silahkan Pilih Sumber Data Dahulu'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }

    const series =  props.series;

    return { options, series }
}

export const configStackBar = () => {

}

export const configBarNegativeValue = (props) => {

    const series = props.series;
    
      const options = {
        chart: {
          type: 'bar',
          height: 380,
          stacked: true
        },
        colors: ['#6359CA', '#FF6674'],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: (Math.max(...props.series[0].data) + Math.max(...props.series[1].data) + (Math.max(...props.series[0].data) + Math.max(...props.series[1].data))) * -1,
          max: Math.max(...props.series[0].data) + Math.max(...props.series[1].data) + (Math.max(...props.series[0].data) + Math.max(...props.series[1].data)),
          title: {
            text: 'Range Umur',
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: (val) => {
              return NumberSeparator(val)
            }
          },
          y: {
            formatter: (val) => {
              return NumberSeparator(Math.abs(val))
            }
          }
        },
        title: {
          text: props.title.length > 0 ? props.title[0].label : 'Silahkan Pilih Sumber Data Dahulu'
        },
        xaxis: {
          categories: props.labels,
          title: {
            text: ''
          },
          labels: {
            formatter: function (val) {
              return NumberSeparator(Math.abs(Math.round(val)))
            }
          }
        },
      };

    return {options, series}
}