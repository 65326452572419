
import { BarChart, PieChart } from 'pages-component/SkeletonLoading';
import React, { Fragment, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

const ApexChart = (props) => {
  const {config, type} = props;

  const Loading = () => {
    let loadingView;

    switch (type) {
      case 'bar':
          loadingView = <BarChart />;
        break; 
      case 'pie':
          loadingView = <PieChart />;
        break;
      case 'donut':
          loadingView = <PieChart />;
        break;
    
      default:
        loadingView = <BarChart />;
        break;
    }

    return loadingView;
  }

  useEffect(() => {
    console.log('Apex Chart',props);
  }, [])
  return (
    <Fragment>
        <Col lg="6" md="6" sm="12" className="px-0 pr-2 mb-2">
          <Card className="h-100">
              <CardBody className="pb-0">
                {
                  config.series.length <= 0 ? <Loading /> : 
                  <div id='chart'>
                      <Chart options={config.options} series={config.series} type={type} />
                  </div>
                }
              </CardBody>
              <CardFooter className="pb-4 font-weight-bold">
                Hasil Pendataan 2022 
              </CardFooter>
          </Card>
        </Col>
    </Fragment>
  );
}

export default ApexChart;