import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import ModelViewChart from 'pages-public/TopikDataPublik/ModelViewChart';


const Kesehatan = () => {
  const { ReducersKota, ReducersKependudukan, ReducersBumil, ReducersKesehatanBayi, ReducersDisabilitas, ReducersKankerPerempuan, ReducersHivTb, ReducersKesehatanMsy, ReducersPusWus } = useSelector(state => state);
  const [modelChartSatu, setModelChartSatu] = useState(['donut', 'pie'])

  return (
    <Fragment>
        <div id='section-satu' className="hero-wrapper pt-3 pb-3 bg-gradient-primary">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div className="bg-composed-wrapper--content pb-2 pt-2">
                <div className="container text-white">
                <Row>
                    <Col lg="6" className="px-0">
                    <div className="text-start">
                        
                        <div className="">
                        <h3 className="font-weight-bold">
                            Dashboard Topik Kesehatan
                        </h3>
                        <p className="mb-3" style={{
                            fontSize: '12px'
                        }}>
                            Data Update hasil pendataan 2022
                        </p>
                        </div>
                    </div>
                    </Col>
                    <Col lg="6" className="px-0 mx-auto d-flex align-items-center pl-5">
                        
                    </Col>
                </Row>
                </div>
            </div>
            </div>
        </div>

        <ModelViewChart 
            title="Grafik Data Ibu Hamil"
            master={ ReducersKependudukan }
            payload= { ReducersBumil }
            params={ ReducersKota }
            fieldReactSelected={ ReducersBumil.fieldReactSelected }
            type={modelChartSatu[Math.floor(Math.random() * modelChartSatu.length)]} 
        />

        <ModelViewChart 
            title="Grafik Data PUS & WUS"
            master={ ReducersKependudukan }
            payload= { ReducersPusWus }
            params={ ReducersKota }
            type={modelChartSatu[Math.floor(Math.random() * modelChartSatu.length)]}  
        />

        <ModelViewChart 
            title="Grafik Data Kontrasepsi"
            master={ ReducersKependudukan }
            payload= { ReducersKesehatanMsy }
            params={ ReducersKota }
            type={modelChartSatu[Math.floor(Math.random() * modelChartSatu.length)]}  
        />

        <ModelViewChart 
            title="Grafik Kesehatan Bayi"
            master={ ReducersKependudukan }
            payload= { ReducersKesehatanBayi }
            params={ ReducersKota }
            type={modelChartSatu[Math.floor(Math.random() * modelChartSatu.length)]}  
        />

        <ModelViewChart 
            title="Grafik Data Disabilitas"
            master={ ReducersKependudukan }
            payload= { ReducersDisabilitas }
            params={ ReducersKota }
            type={modelChartSatu[Math.floor(Math.random() * modelChartSatu.length)]}  
        />

        <ModelViewChart 
            title="Grafik Data Kanker Perempuan"
            master={ ReducersKependudukan }
            payload= { ReducersKankerPerempuan }
            params={ ReducersKota }
            type={modelChartSatu[Math.floor(Math.random() * modelChartSatu.length)]}  
        />

        <ModelViewChart 
            title="Grafik Data HIV dan TB"
            master={ ReducersKependudukan }
            payload= { ReducersHivTb }
            params={ ReducersKota }
            type={modelChartSatu[Math.floor(Math.random() * modelChartSatu.length)]}  
        />

        <div className='mb-5' />
    </Fragment>
  );
};

export default Kesehatan;