import { CharacterRemove } from "helpers";

const SetFieldData = (payload) => {
    let tmpField = [];
    let tmpFieldReactSelect = [];
    Object.keys(payload.master.length > 0 ? payload.master[0] : []).map((key0, index) => {
    if (["id", "kota", "kecamatan", "kelurahan", "rw", "rt", "createdAt", "updatedAt", "tahun","kode_wilayah"].includes(key0) ) {
        return false;
    }

    tmpField.push(key0);
        tmpFieldReactSelect.push({
            label : CharacterRemove(key0, /_/g, ' ').replace('jumlah ', ''),
            value : key0
        });
    })

    return { tmpField, tmpFieldReactSelect }
}

export default SetFieldData;