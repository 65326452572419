export * from './ModelDataChart';
export * from './ConfigChart';
export * from './Reducers';
export { default as count } from './CountData';

export const NumberSeparator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const SeparatorRemove = (x) => {
    return x.replace(',', '')
}

export const CharacterRemove = (str, find, replace) => {
    return str.replace(find, replace)
}

export const CapitalizeEachWord = (str) => {
    const words = str.split(" ");

    return words.map((word) => { 
        return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
}