import React, { Fragment } from 'react';
import { Row, Col, ListGroup, CardHeader, ListGroupItemText, ListGroupItemHeading, ListGroupItem, Nav, NavItem, NavLink } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IndikatorKependudukan from 'config/indikator-kependudukan';


const AnalisaKependudukan = () => {
  return (
    <Fragment>
        <Row className="bg-thrid text-white" style={{
            minHeight: "4vh",
            lineHeight: "4vh",
            borderRadius: "1vh 1vh 0 0"
        }}>
            <Col md="3">
                Indikator Data Kependudukan
            </Col>
            <Col md="6" lg="6" className="text-center">
                Grafik
            </Col>
            <Col md="3">
                Diskusi
            </Col>
        </Row>
        <Row style={{
            height: "70vh"
        }}>
            <Col md="3" className="pl-0 pr-0 bg-white">
                <div className='scroll-area shadow-overflow'>
                    <PerfectScrollbar>
                        <ListGroup flush>
                            {
                                IndikatorKependudukan ? IndikatorKependudukan.map((res, i) => {
                                    return  <ListGroupItem>
                                    <ListGroupItemText>
                                    {res.title}
                                    </ListGroupItemText>
                                </ListGroupItem>
                                }) : <ListGroupItem>
                                <ListGroupItemText>
                                Loading
                                </ListGroupItemText>
                            </ListGroupItem>
                            }
                            
                        </ListGroup>
                    </PerfectScrollbar>
                </div>
            </Col>
            <Col md="6" lg="6" className="bg-white pt-3">
                <div>
                    <Nav pills>
                        <NavItem>
                            <NavLink
                                active
                                href="#"
                            >
                                Grafik
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                            >
                                Maps
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </Col>
            <Col md="3">
                Diskusi
            </Col>
        </Row>
    </Fragment>
  );
};

export default AnalisaKependudukan;
