import { SET_MASTER } from "../Action"
import initialState from "./InitialState";

export default function ReducersVariabel(state = initialState, action){
    switch (action.type) {
        case SET_MASTER:
            console.log(action);
            return {
                ...state,
                ...action.payload
              }
            break;

        default:
            break;
    }

    return state;
}